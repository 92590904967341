import { useContext } from 'react'
import { pluralize } from 'utils/src/text/string'
import { Tag } from '../../../../types'
import { EditPanelContext } from '../../../contexts/panel'

export const AddTagsMessage = ({ tags = [] }: { tags: Tag[] }) => {
    const tagsString = tags.map((tag) => `#${tag.text}`).join(', ')
    const tagsLabel = pluralize('tag', tags.length)

    return tags.length > 70 ? (
        <span>
            Added{' '}
            <strong>
                {tagsString.length} {tagsLabel}
            </strong>
        </span>
    ) : (
        <span>
            Added {tagsLabel} <em>{tagsString}</em>
        </span>
    )
}

export const DeleteMessage = () => {
    const { gifIds } = useContext(EditPanelContext)
    return <span>Deleted {pluralize('GIF', gifIds.length)}</span>
}

export const FeatureTagMessage = ({ tag }: { tag: string }) => (
    <span>
        Featured tag <em>#{tag}</em>
    </span>
)

export const RemoveTagMessage = ({ tag }: { tag: Tag }) => (
    <span>
        Removed tag <em>#{tag.text}</em>
    </span>
)

export const ScheduleMessage = ({ date }: { date: Date }) => (
    <span>
        Scheduled to trend on{' '}
        <strong>
            {date.toLocaleDateString('en-US', {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
            })}
        </strong>{' '}
        at{' '}
        <strong>
            {date.toLocaleTimeString('en-US', {
                hour: 'numeric',
                minute: '2-digit',
            })}
        </strong>
    </span>
)

export const UnfeatureTagMessage = ({ tag }: { tag: string }) => (
    <span>
        Unfeatured tag <em>#{tag}</em>
    </span>
)
