import styled from 'styled-components'
import IconButton from 'ui/src/components/controls/icon-button'
import { heightFromControlSize } from 'utils/src/control-size'

export const Arrow = styled(IconButton)`
    flex-shrink: 0;
    width: ${heightFromControlSize('small')}px;
`

export const Container = styled.div<{ $color: string }>`
    align-items: center;
    background: ${({ $color }) => $color};
    border-radius: 4px;
    box-sizing: border-box;
    display: flex;
    gap: 10px;
    justify-content: center;
    padding: 4px;
    user-select: none;
    width: 100%;
`
