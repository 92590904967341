import { ReactNode, useContext, useEffect, useRef, useState } from 'react'
import { useAlert } from 'ui/src/components/alert'
import { useGlobalTooltip } from 'ui/src/components/controls/tooltip/hooks/use-global-tooltip'
import Alert from '../components/alert'
import { EditPanelActionContext } from '../contexts/action'
import { EditPanelApiContext } from '../contexts/api'
import { EditPanelInternalActionContext } from '../contexts/internal-action'
import { EditPanelMessageContext } from '../contexts/message'
import { EditPanelContext } from '../contexts/panel'
import {
    EditPanelAction,
    EditPanelActionAlert,
    EditPanelExternalAction,
    EditPanelInternalAction,
} from '../types/action'
import { isInternalAction, titleFromAction } from '../utilities/action'
import {
    actionRequiresConfirmation,
    actionRequiresModal,
    alertDescriptionFromAction,
    alertGradientFromAction,
    alertTitleFromAction,
} from '../utilities/alert'
import { hasActionPermission } from '../utilities/permissions'

const DEFAULT_ALERT: EditPanelActionAlert = { title: '' }

const EditPanelActionContextManager = ({ children }: { children: ReactNode }) => {
    const promiseResolveRef = useRef<(value: string) => void>(() => {})
    const { onAction } = useContext(EditPanelApiContext)
    const { gifIds, permissions } = useContext(EditPanelContext)
    const { performInternalAction } = useContext(EditPanelInternalActionContext)
    const { showActionMessage, showErrorMessage } = useContext(EditPanelMessageContext)
    const [alert, setAlert] = useState<EditPanelActionAlert>(DEFAULT_ALERT)
    const { closeTooltip } = useGlobalTooltip()

    const { isOpen, closeAlert, openAlert } = useAlert({
        closeable: true,
        description: alert.description,
        title: alert.title,
    })

    async function performAction<T>(action: EditPanelAction, value: T) {
        // check for permission
        if (!hasActionPermission(action, permissions)) {
            showErrorMessage(`You don't have permission to perform this action.`, 'no-permission')
            return
        }
        // show alert and await confirmation if required
        if (actionRequiresConfirmation(action)) {
            await showAlert(action, value)
        }

        try {
            if (isInternalAction(action)) {
                await performInternalAction(action as EditPanelInternalAction, value)
            } else {
                await onAction(gifIds, action as EditPanelExternalAction, value)
            }
            if (!actionRequiresModal(action)) {
                showActionMessage(action, value)
            }
        } catch (err) {
            showErrorMessage(`An error occurred while attempting to ${titleFromAction(action).toLowerCase()}`, action)
        }
    }

    async function showAlert<T>(action: EditPanelAction, value: T) {
        // make sure any open tooltips close
        closeTooltip()

        setAlert({
            continueGradient: alertGradientFromAction(action),
            continueLabel: titleFromAction(action),
            description: alertDescriptionFromAction(action, value, gifIds.length),
            title: alertTitleFromAction(action, gifIds.length),
        })
        openAlert()

        return new Promise((resolve) => {
            promiseResolveRef.current = resolve
        })
    }

    function onContinue() {
        promiseResolveRef.current('')
        closeAlert()
    }

    useEffect(() => {
        if (!isOpen) {
            promiseResolveRef.current = () => {}
            setAlert(DEFAULT_ALERT)
        }
    }, [isOpen])

    return (
        <EditPanelActionContext.Provider
            value={{
                performAction,
            }}
        >
            {children}
            <Alert
                gradient={alert.continueGradient}
                isOpen={isOpen}
                label={alert.continueLabel}
                onCancel={() => closeAlert()}
                onContinue={onContinue}
            />
        </EditPanelActionContext.Provider>
    )
}

export default EditPanelActionContextManager
