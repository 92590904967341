import { EditPanelField } from '../../../types/field'
import {
    CtaMessage,
    DefaultMessage,
    IndexedMessage,
    NoTrendMessage,
    PrivacyMessage,
    RatingMessage,
    UsernameMessage,
} from './messages'

type Props = {
    field: EditPanelField
    value: any
}

const EditPanelFieldMessage = ({ field, value }: Props) => {
    switch (field) {
        case 'cta':
            return <CtaMessage cta={value} />
        case 'indexed':
            return <IndexedMessage isIndexed={value} />
        case 'no-trend':
            return <NoTrendMessage noTrend={value} />
        case 'privacy':
            return <PrivacyMessage isPrivate={value} />
        case 'rating':
            return <RatingMessage rating={value} />
        case 'username':
            return <UsernameMessage username={value} />
        default:
            return <DefaultMessage field={field} value={value} />
    }
}

export default EditPanelFieldMessage
