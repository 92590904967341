import styled, { css } from 'styled-components'
import { Gradient } from 'types'
import { gradientToCss } from 'utils/src/gradient'

const blockCss = css`
    border-radius: inherit;
    height: 100%;
    width: 100%;
`

export const Cascade = styled.div`
    ${blockCss}
    pointer-events: none;
    position: relative;
`

export const Block = styled.div<{ $gradient: Gradient }>`
    ${blockCss}
    box-shadow: 0px 4px 8px 0px rgba(33, 37, 41, 0.30);
    left: 0;
    position: absolute;
    top: 0;

    ${({ $gradient }) => css`
        background: ${gradientToCss($gradient)};
    `}
`
