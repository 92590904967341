import { lazy, Suspense } from 'react'
import { EditPanelField } from '../../../types/field'

const Source = lazy(() => import('./source'))
const Username = lazy(() => import('./username'))

export default ({ field, isFieldOpen }: { field: EditPanelField; isFieldOpen: boolean }) => {
    function getComponent() {
        switch (field) {
            case 'source':
                return <Source isFieldOpen={isFieldOpen} />
            case 'username':
                return <Username />
            default:
                return null
        }
    }

    return <Suspense>{getComponent()}</Suspense>
}
