import styled, { css } from 'styled-components'
import { GRID_SPACING, GRID_SPACING_LARGE } from '../constants'
import PreviewComponent from './preview'

export const AdditionalFields = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${GRID_SPACING}px;
`

export const Preview = styled(PreviewComponent)`
    flex-shrink: 0;
`

export const Split = styled.div<{ $compact: boolean }>`
    display: flex;

    ${({ $compact }) =>
        $compact
            ? css`
                  flex-direction: column;
                  gap: inherit;

                  ${Preview} {
                      max-width: none;
                  }
              `
            : css`
                  flex-direction: row-reverse;
                  gap: ${GRID_SPACING_LARGE}px;
              `}
`
