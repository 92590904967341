import { giphyDarkestGrey, giphyLightGrey, giphyLightestGrey } from '@giphy/colors'
import { uniqueId } from 'lodash'
import { CSSProperties, ReactNode, useEffect, useRef, useState } from 'react'
import { ControlSize } from 'types/src/controls'
import { Gradient } from 'types/src/gradient'
import { CalendarContext } from './context'
import Dates from './dates'
import Days from './days'
import Header from './header'
import { useVisibleMonth } from './hooks/use-visible-month'
import { Container, Grid } from './style'
import { dateToTime } from './utilities/date'

type Props = {
    backgroundColor?: string
    buttonColor?: string
    children?: ReactNode
    className?: string
    color?: string
    defaultValue?: Date
    disabled?: boolean
    gradient?: Gradient
    headerColor?: string
    headerFontFamily?: string
    headerFontSize?: number
    highlightColor?: string
    labelColor?: string
    max?: Date
    min?: Date
    noHeader?: boolean
    size?: ControlSize
    style?: CSSProperties
    onChange?: (date?: Date) => void
}

const Calendar = ({
    backgroundColor,
    buttonColor = giphyDarkestGrey,
    children,
    className,
    color = giphyLightGrey,
    defaultValue,
    disabled = false,
    gradient,
    headerColor = '#fff',
    headerFontFamily,
    headerFontSize = 28,
    highlightColor = '#fff',
    labelColor = giphyLightestGrey,
    max,
    min,
    noHeader = false,
    size = 'small',
    style,
    onChange,
}: Props) => {
    const idRef = useRef<string>(uniqueId('calendar'))
    const defaultValueRef = useRef<Date | null | undefined>(defaultValue)
    const [today] = useState<Date>(new Date())
    const [selected, setSelected] = useState<Date | undefined>(defaultValue)
    const [time, setTime] = useState<string>(dateToTime(selected || today))
    const [visible, setVisible] = useState<Date>(defaultValue || today)
    const visibleMonth = useVisibleMonth(visible)

    useEffect(() => {
        if (selected?.getTime() !== defaultValueRef.current?.getTime()) {
            defaultValueRef.current = null
            onChange?.(selected)
        }
    }, [selected, onChange])

    return (
        <CalendarContext.Provider
            value={{
                colors: {
                    button: buttonColor,
                    default: color,
                    gradient,
                    header: headerColor,
                    highlight: highlightColor,
                    label: labelColor,
                },
                id: idRef.current,
                max,
                min,
                selected,
                time,
                today,
                visible,
                visibleMonth,
                setSelected,
                setTime,
                setVisible,
            }}
        >
            <Container $disabled={disabled} className={className} style={style}>
                {!noHeader && <Header fontFamily={headerFontFamily} fontSize={headerFontSize} />}
                <Grid $background={backgroundColor} $size={size}>
                    <Days />
                    <Dates />
                </Grid>
                {children}
            </Container>
        </CalendarContext.Provider>
    )
}

export default Calendar
