import { useContext } from 'react'
import { ControlSize } from 'types/src/controls'
import { useGlobalTooltip } from 'ui/src/components/controls/tooltip/hooks/use-global-tooltip'
import { EditPanelActionContext } from '../../../contexts/action'
import { EditPanelContext } from '../../../contexts/panel'
import { EditPanelAction } from '../../../types/action'
import { iconFromAction, titleFromAction } from '../../../utilities/action'
import { Button, Icon } from './style'

type Props = {
    action: EditPanelAction
    className?: string
    iconSize?: number
    size: ControlSize
    textColor?: string
    value?: any
}

const EditPanelMenuActionButton = ({ action, className, iconSize = 16, size, textColor, value }: Props) => {
    const { colors } = useContext(EditPanelContext)
    const { performAction } = useContext(EditPanelActionContext)
    const { closeTooltip } = useGlobalTooltip()
    const icon = iconFromAction(action)
    const label = titleFromAction(action)

    function onClick() {
        closeTooltip()
        performAction(action, value)
    }

    return (
        <Button
            className={className}
            color="transparent"
            disabled={typeof value !== 'undefined' && !value}
            icon={<Icon height={iconSize} icon={icon} width={iconSize} />}
            iconLocation="right"
            size={size}
            textColor={textColor || colors.text}
            onClick={() => onClick()}
        >
            {label}
        </Button>
    )
}

export default EditPanelMenuActionButton
