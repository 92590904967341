import { useContext } from 'react'
import { EditPanelContext } from '../contexts/panel'
import { Divider } from '../style'
import Fields from './fields'
import { AdditionalFields, Preview, Split } from './style'
import Tags from './tags'

type Props = {
    className?: string
}

const EditPanelInfo = ({ className }: Props) => {
    const { colors, isCompact } = useContext(EditPanelContext)

    return (
        <div className={className}>
            <Split $compact={isCompact}>
                <Preview />
                <Fields />
            </Split>
            <Divider $color={colors.divider} />
            <AdditionalFields>
                <Tags />
            </AdditionalFields>
        </div>
    )
}

export default EditPanelInfo
