import { useContext, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Language, allLanguages } from 'types/src/language'
import SelectList from 'ui/src/components/controls/select-list'
import { useGlobalTooltip } from 'ui/src/components/controls/tooltip/hooks/use-global-tooltip'
import { flagFromLanguage, labelFromLanguage, titleFromLanguage } from 'utils/src/language'
import TooltipButton from '../../components/button/tooltip'
import { CONTROL_SIZE } from '../../constants'
import { EditPanelContext } from '../../contexts/panel'

const Button = styled(TooltipButton)`
    border-radius: 0;
    flex-shrink: 0;
    font-size: 1.25em;
    padding: 0;
`

const LanguageCode = styled.span`
    font-size: 13px;
    opacity: 0.5;
`

const LanguageList = styled(SelectList)`
    max-height: 300px;
    overflow: auto;
    padding: 0;
    width: 100%;
`

type Props = {
    className?: string
    defaultValue?: Language
    onChange: (language?: Language) => void
}

export default ({ className, defaultValue = 'en', onChange }: Props) => {
    const defaultValueRef = useRef<Language | undefined>(defaultValue)
    const { colors } = useContext(EditPanelContext)
    const [language, setLanguage] = useState<Language>(defaultValue)
    const { closeTooltip } = useGlobalTooltip()
    const languageIndex = allLanguages.indexOf(language)

    function onLanguageSelect(index: number) {
        if (index !== languageIndex) {
            setLanguage(allLanguages[index])
            closeTooltip()
        }
    }

    useEffect(() => {
        if (language !== defaultValueRef.current) {
            defaultValueRef.current = undefined
            onChange(language === 'en' ? undefined : language)
        }
    }, [language])

    return (
        <Button
            className={className}
            color={colors.input}
            label={flagFromLanguage(language)}
            size={CONTROL_SIZE}
            title={labelFromLanguage(language)}
        >
            <LanguageList
                iconColor={colors.headerHighlight}
                index={languageIndex}
                options={allLanguages.map((lang) => (
                    <span>
                        {titleFromLanguage(lang)} <LanguageCode>({lang})</LanguageCode>
                    </span>
                ))}
                onChange={onLanguageSelect}
            />
        </Button>
    )
}
