import { Tag } from 'edit/types'
import { updateGif } from './gif'
import { addLanguageTags, removeLanguageTag } from './language-tag'

export const addTags = async (gifIds: string[], tags: Tag[]) => {
    const languages = tags.map((t) => t.language || 'en').filter((lang, i, self) => self.indexOf(lang) === i)
    const promises: Promise<any>[] = []

    gifIds.forEach((id) => {
        // all same language
        if (languages.length === 1 && languages[0] !== 'en') {
            promises.push(
                addLanguageTags(
                    id,
                    tags.map((t) => t.text),
                    languages[0]
                )
            )
        } else {
            tags.forEach((tag) => {
                if (tag.language && tag.language !== 'en') {
                    promises.push(addLanguageTags(id, [tag.text], tag.language))
                } else {
                    promises.push(addTagStrings([id], [tag.text]))
                }
            })
        }
    })

    return Promise.all(promises)
}

export const addTagStrings = async (gifIds: string[], tags: string[]) => {
    const promises: Promise<any>[] = []
    gifIds.forEach((id) => {
        tags.forEach((tag) => {
            promises.push(updateGif(id, 'create_tag', tag))
        })
    })
    return Promise.all(promises)
}

export const removeTag = async (gifIds: string[], tag: Tag) => {
    return Promise.all(
        gifIds.map((id) => {
            if (tag.language && tag.language !== 'en') {
                return removeLanguageTag(id, tag.text, tag.language)
            }
            return removeTagString([id], tag.text)
        })
    )
}

export const removeTagString = async (gifIds: string[], tag: string) => {
    return Promise.all(gifIds.map((id) => updateGif(id, 'delete_tag', tag)))
}
