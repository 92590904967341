import { useContext } from 'react'
import styled from 'styled-components'
import MenuActionButton from '../../components/button/menu-action'
import { EditPanelContext } from '../../contexts/panel'
import { EditPanelAction } from '../../types/action'
import { tagStringsFromGifs } from '../../utilities/tags'

const Menu = styled.div`
    padding: 10px 5px;
`

type Props = {
    actions: EditPanelAction[]
    className?: string
}

export default ({ actions, className }: Props) => {
    const { gifs, permissions } = useContext(EditPanelContext)
    const tags = tagStringsFromGifs(gifs, permissions.staff)

    function getValue(action: EditPanelAction): any {
        switch (action) {
            case 'copy-all-tags':
                return tags.join(',')
            default:
                return undefined
        }
    }

    return (
        <Menu className={className}>
            {actions.map((action) => (
                <MenuActionButton
                    action={action}
                    key={`edit-panel-ellipsis-menu-${action}`}
                    size="small"
                    value={getValue(action)}
                />
            ))}
        </Menu>
    )
}
