import { giphyDarkestGrey } from '@giphy/colors'
import { IGif } from '@giphy/js-types'
import { CSSProperties, useEffect, useRef, useState } from 'react'
import MediaPreview from '../preview'
import { Arrow, Container } from './style'

type Props = {
    arrowColor?: string
    arrowIconColor?: string
    backgroundColor?: string
    className?: string
    defaultIndex?: number
    index?: number
    gifs: IGif[]
    previewHeight: number
    previewWidth: number
    style?: CSSProperties
    onChange: (index: number) => void
}

export const MediaNavigation = ({
    arrowColor,
    arrowIconColor,
    backgroundColor = giphyDarkestGrey,
    className,
    defaultIndex = 0,
    gifs,
    index: indexProp,
    previewHeight,
    previewWidth,
    style,
    onChange,
}: Props) => {
    const defaultIndexRef = useRef<number | undefined>(indexProp || defaultIndex)
    const [index, setIndex] = useState<number>(indexProp || defaultIndex)
    const gif = gifs[index]

    function previous() {
        setIndex((state) => state - 1)
    }

    function next() {
        setIndex((state) => state + 1)
    }

    useEffect(() => {
        if (typeof indexProp !== 'undefined') {
            setIndex(indexProp)
        }
    }, [indexProp])

    useEffect(() => {
        if (index !== defaultIndexRef.current) {
            defaultIndexRef.current = undefined
            onChange?.(index)
        }
    }, [index])

    return (
        <Container $color={backgroundColor} className={className} style={style}>
            <Arrow
                color={arrowColor}
                disabled={index <= 0}
                icon="navigate-left"
                iconColor={arrowIconColor}
                size="mini"
                onClick={() => previous()}
            />
            <MediaPreview backgroundColor="transparent" gifs={[gif]} height={previewHeight} width={previewWidth} />
            <Arrow
                color={arrowColor}
                disabled={index >= gifs.length - 1}
                icon="navigate-right"
                iconColor={arrowIconColor}
                size="mini"
                onClick={() => next()}
            />
        </Container>
    )
}

export default MediaNavigation
