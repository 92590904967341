import styled, { css } from 'styled-components'
import BadgeComponent from 'ui/src/components/controls/badge'
import CascadeComponent, { CASCADE_OFFSET, CASCADE_SIZE } from './cascade'

export const Badge = styled(BadgeComponent)`
    box-shadow: 0px 3px 6px 0px rgba(33, 37, 41, 0.3);
`

export const Cascade = styled(CascadeComponent)`
    left: 0;
    position: absolute;
    top: 0;
`

export const Media = styled.div<{ $bulk: boolean }>`
    border-radius: 4px;
    box-sizing: border-box;
    position: relative;

    ${Badge} {
        position: absolute;
        right: ${CASCADE_SIZE}px;
        top: ${CASCADE_SIZE}px;
    }

    ${({ $bulk }) =>
        $bulk &&
        css`
            margin-bottom: ${CASCADE_OFFSET}px;
            margin-right: ${CASCADE_OFFSET}px;
        `}
`
