import { useMemo } from 'react'
import { dateWithNoTime } from '../utilities/date'

export function useIsPreviousDisabled(dates: Date[], min?: Date) {
    return useMemo(() => {
        if (!min) {
            return false
        }

        const first = dates[0]

        if (first && first.getTime() <= dateWithNoTime(min).getTime()) {
            return true
        }

        return false
    }, [dates, min])
}
