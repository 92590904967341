import { giphyBlack, giphyDarkCharcoal, giphyDarkGrey, giphyDarkestGrey, giphyLightGrey } from '@giphy/colors'
import { EditPanelColors } from '../types/colors'
import { DEFAULT_COLORS } from './defaults'

export default {
    ...DEFAULT_COLORS,
    backgroundColor: giphyDarkestGrey,
    controlDark: giphyDarkGrey,
    footerBackground: giphyBlack,
    headerClose: giphyDarkCharcoal,
    headerHighlight: '#fff',
    headerInactive: giphyLightGrey,
} as EditPanelColors
