import styled, { css } from 'styled-components'
import {
    CONTROL_SPACING,
    CONTROL_SPACING_LARGE,
    GRID_SPACING,
    METADATA_LABEL_WIDTH,
    METADATA_TIME_WIDTH,
} from '../../constants'

export const Grid = styled.div<{ $compact: boolean }>`
    display: grid;
    gap: ${CONTROL_SPACING_LARGE}px ${GRID_SPACING}px;
    width: 100%;

    > div {
        display: grid;
        gap: ${CONTROL_SPACING}px;
        grid-template-columns: ${METADATA_LABEL_WIDTH}px auto ${METADATA_TIME_WIDTH}px;
    }

    ${({ $compact }) =>
        $compact
            ? css`
                  display: flex;
                  flex-direction: column;
              `
            : css`
                  grid-template-columns: repeat(2, 1fr);
              `}
`
