import { useContext, useState } from 'react'
import styled from 'styled-components'
import Button from 'ui/src/components/controls/button'
import CalendarComponent from 'ui/src/components/controls/calendar'
import { CalendarTimeAddOn } from 'ui/src/components/controls/calendar/add-ons'
import { useGlobalTooltip } from 'ui/src/components/controls/tooltip/hooks/use-global-tooltip'
import { CONTROL_SPACING } from '../../constants'
import { EditPanelActionContext } from '../../contexts/action'
import { EditPanelContext } from '../../contexts/panel'

const Calendar = styled(CalendarComponent)`
    padding: 20px 15px 15px;
`

const Buttons = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${CONTROL_SPACING}px;
`

export default () => {
    const { colors } = useContext(EditPanelContext)
    const { performAction } = useContext(EditPanelActionContext)
    const [date, setDate] = useState<Date | undefined>()
    const now = new Date()

    const { closeTooltip } = useGlobalTooltip()

    function onSchedule() {
        if (date) {
            performAction('schedule', date)
            closeTooltip()
        }
    }

    return (
        <Calendar
            backgroundColor={colors.control}
            buttonColor={colors.button}
            color={colors.buttonText}
            gradient={colors.gradient}
            headerColor={colors.header}
            labelColor={colors.buttonText}
            min={now}
            size="small"
            onChange={setDate}
        >
            <CalendarTimeAddOn toggleColor={colors.text} />
            <Buttons>
                <Button disabled={!date} gradient={colors.gradient} size="medium" onClick={() => onSchedule()}>
                    Add to Schedule Queue
                </Button>
            </Buttons>
        </Calendar>
    )
}
