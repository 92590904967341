import styled, { css } from 'styled-components'
import { ControlSize } from 'types/src/controls'
import { borderRadiusFromControlSize, fontSizeFromControlSize, heightFromControlSize } from 'utils/src/control-size'

export const Container = styled.div<{ $disabled: boolean }>`
    display: grid;
    gap: 15px;
    grid-template-columns: 1fr;
    text-align: left;
    user-select: none;

    ${({ $disabled }) =>
        $disabled &&
        css`
            pointer-events: none;
            opacity: 0.5;
        `}
`

export const Grid = styled.div<{ $background?: string; $size: ControlSize }>`
    display: grid;
    grid-template-columns: repeat(7, 1fr);

    ${({ $size }) => css`
        font-size: ${fontSizeFromControlSize($size)}px;
        grid-auto-rows: ${heightFromControlSize($size)}px;
    `}

    ${({ $background, $size }) =>
        $background &&
        css`
            background: ${$background};
            border-radius: ${borderRadiusFromControlSize($size)}px;
            padding: 0.6em;
        `}

    > button,
    > div {
        align-items: center;
        display: flex;
        justify-content: center;

        strong {
            font-weight: bold;
        }
    }
`
