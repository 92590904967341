import { useContext } from 'react'
import Collapsible from '../../../controls/collapsible'
import { CONTROL_SIZE_SLIM } from '../../constants'
import { EditPanelContext } from '../../contexts/panel'
import Field from '../../field'
import { defaultOpenFromField, iconFromField, titleFromField } from '../../utilities/field'
import { Grid } from './style'

type Props = {
    className?: string
    gifId: string
}

const EditPanelMetadataInfo = ({ className, gifId }: Props) => {
    const { fields, isCompact } = useContext(EditPanelContext)

    return (
        <Grid $compact={isCompact} className={className}>
            {fields.map((field, i) => {
                const disabled = !isCompact
                const defaultOpen = disabled || defaultOpenFromField(field)
                const icon = iconFromField(field)
                const title = titleFromField(field)

                return (
                    <Collapsible
                        defaultOpen={defaultOpen}
                        disabled={disabled}
                        icon={icon}
                        key={`edit-panel-metadata-field-${field}-${i}`}
                        title={title}
                    >
                        <Field field={field} gifId={gifId} size={CONTROL_SIZE_SLIM} />
                    </Collapsible>
                )
            })}
        </Grid>
    )
}

export default EditPanelMetadataInfo
