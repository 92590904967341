import { giphyYellow } from '@giphy/colors'
import styled, { css } from 'styled-components'
import IconComponent from 'ui/src/components/icon'

export const Arrow = styled(IconComponent)<{ $open: boolean }>`
    flex-shrink: 0;
    margin: 0 2px;
    transition: all 0.15s ease-out;

    ${({ $open }) =>
        $open
            ? css`
                  rotate: -180deg;
              `
            : css`
                  opacity: 0.5;
              `}
`

export const Icon = styled(IconComponent)`
    flex-shrink: 0;
`

export const Toggle = styled.button<{ $disabled: boolean }>`
    all: unset;
    align-items: center;
    cursor: pointer;
    display: flex;
    font-weight: bold;
    gap: 6px;
    line-height: normal;
    user-select: none;
    white-space: nowrap;

    &:active {
        opacity: 0.8;
    }

    &:focus-visible {
        border-radius: 4px;
        outline: 2px solid ${giphyYellow};
    }

    ${({ $disabled }) =>
        $disabled &&
        css`
            pointer-events: none;
        `}
`
