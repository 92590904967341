import { giphyDarkCharcoal } from '@giphy/colors'
import { CSSProperties, ReactNode, useRef, useState } from 'react'
import { ControlSize } from 'types/src/controls'
import { Gradient } from 'types/src/gradient'
import IconButton from 'ui/src/components/controls/icon-button'
import TextInput, { TextInputRef } from 'ui/src/components/controls/text-input'

type Props = {
    allowGiphyTags?: boolean
    autoFocus?: boolean
    buttonColor?: string
    buttonGradient?: Gradient
    buttonIconColor?: string
    children?: ReactNode
    className?: string
    color?: string
    defaultValue?: string
    disabled?: boolean
    placeholder?: string
    size: ControlSize
    style?: CSSProperties
    onAdd?: (tags: string[]) => void
}

const TagInput = ({
    allowGiphyTags = false,
    autoFocus,
    buttonColor = giphyDarkCharcoal,
    buttonGradient,
    buttonIconColor,
    children,
    className,
    color = '#fff',
    defaultValue = '',
    disabled,
    placeholder = 'Add Tags',
    size,
    style,
    onAdd,
}: Props) => {
    const inputRef = useRef<TextInputRef>(null)
    const [value, setValue] = useState<string>(defaultValue)

    function onClick() {
        if (!value) return

        const tagStrings = value.split(',').map((tag) => {
            return tag.trim().replace(/#/g, '').toLowerCase()
        })

        const tags: string[] = tagStrings
            .filter((tag, i) => {
                return tag.length > 0 && tagStrings.indexOf(tag) === i
            })
            .filter((tag) => {
                return allowGiphyTags || tag.indexOf('giphy') !== 0
            })

        // clear input and set focus back on input
        setValue('')
        inputRef.current?.focus()

        onAdd?.(tags)
    }

    return (
        <TextInput
            autoFocus={autoFocus}
            className={className}
            color={color}
            disabled={disabled}
            placeholder={placeholder}
            ref={inputRef}
            size={size}
            spellCheck
            style={style}
            value={value}
            onChange={setValue}
            onEnterKeyPress={onClick}
        >
            {children}
            <IconButton
                color={buttonColor}
                gradient={buttonGradient}
                icon="add"
                iconColor={buttonIconColor}
                size={size}
                onClick={onClick}
            />
        </TextInput>
    )
}

export default TagInput
