import styled, { css } from 'styled-components'
import { ControlSize } from 'types/src/controls'
import ToggleComponent from 'ui/src/components/controls/toggle'
import { fontSizeFromControlSize } from 'utils/src/control-size'

export const Container = styled.div<{ $disabled: boolean }>`
    align-items: center;
    display: flex;
    gap: 10px;

    ${({ $disabled }) =>
        $disabled &&
        css`
            opacity: 0.5;
            pointer-events: none;
        `}
`

export const Toggle = styled(ToggleComponent)`
    width: 65px;
`

export const Time = styled.div<{ $size: ControlSize }>`
    align-items: center;
    display: flex;
    font-weight: bold;
    gap: 0.3em;

    ${({ $size }) => css`
        font-size: ${fontSizeFromControlSize($size)}px;
    `}
`
