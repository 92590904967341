import { ReactNode } from 'react'
import Collapsible from './collapsible'
import { componentFromValue } from './utilities/component'

type Props = {
    children?: ReactNode
    className?: string
    label: string
    value: any
}

export default ({ children, className, label, value }: Props) => {
    const isArray = Array.isArray(value)
    const isObject = typeof value === 'object' && !isArray

    // collapsible
    if (value && typeof value === 'object') {
        if ((isArray && value.length) || (isObject && Object.keys(value).length)) {
            return (
                <Collapsible className={className} label={label} value={value}>
                    {children}
                </Collapsible>
            )
        }
    }

    return (
        <div className={className}>
            <strong>{label}</strong>: {componentFromValue(value)}
            {children}
        </div>
    )
}
