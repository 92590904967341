import { useContext } from 'react'
import { CalendarContext } from './context'
import { calendarDays } from './types'
import { labelFromDay } from './utilities/day'

const CalendarDayLabels = () => {
    const { colors, id } = useContext(CalendarContext)

    return (
        <>
            {calendarDays.map((day) => (
                <div key={`${id}-day-labels-${day}`} style={{ color: colors.label }}>
                    <strong>{labelFromDay(day)}</strong>
                </div>
            ))}
        </>
    )
}

export default CalendarDayLabels
