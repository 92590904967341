import { giphyBlack, giphyLightestGrey } from '@giphy/colors'

export const URLS_REGEX = /((http|https|ftp):\/\/[\w?=&.\/-;#~%-]+(?![\w\s?&.\/;#~%"=-]*>))/g

export function openJSONViewer(code: Object, title: string = 'JSON Viewer') {
    try {
        const json = JSON.stringify(code, null, 4)
        const win = window.open()
        const style = `background:${giphyBlack}; color:${giphyLightestGrey};`

        const jsonWithLinks = json.replace(
            URLS_REGEX,
            `<a href="$&" style="all:unset; cursor:pointer;" target="_blank">$&</a>`
        )

        win?.document.open()
        win?.document.write(
            `<html>
                <title>${title}</title>
                <body style="${style}">
                    <pre>${jsonWithLinks}</pre>
                </body>
            </html>`
        )
        win?.document.close()
    } catch (err) {}
}
