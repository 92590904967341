import { useContext, useState } from 'react'
import SelectList from 'ui/src/components/controls/select-list'
import useAsyncEffect from 'utils/src/hooks/use-async-effect'
import { Metadata } from '../../../types'
import DropdownButton from '../../components/button/dropdown'
import { CONTROL_SIZE } from '../../constants'
import { EditPanelApiContext } from '../../contexts/api'
import { EditPanelContext } from '../../contexts/panel'
import Block from './block'
import { Container } from './style'

type Props = {
    className?: string
    gifId: string
    metadata: Metadata
}

const EditPanelMetadataJSON = ({ className, gifId, metadata }: Props) => {
    const { fetchExtendedMetadata } = useContext(EditPanelApiContext)
    const { colors } = useContext(EditPanelContext)
    const [extendedMetadata, setExtendedMetadata] = useState<{ [key: string]: Metadata }>({})
    const [activeExtendedKey, setActiveExtendedKey] = useState<string>('')
    const extendedKeys = Object.keys(extendedMetadata)

    useAsyncEffect(async () => {
        const extended = await fetchExtendedMetadata(gifId)
        setExtendedMetadata(extended)
    }, [gifId])

    return (
        <Container className={className}>
            <Block code={metadata} title="Metadata JSON" />
            <Block
                code={extendedMetadata[activeExtendedKey]}
                placeholder="Select an extended metadata field above..."
                title="Extended JSON"
            >
                <DropdownButton label={activeExtendedKey || 'Select a field'} size={CONTROL_SIZE}>
                    <SelectList
                        iconColor={colors.headerHighlight}
                        index={activeExtendedKey ? extendedKeys.indexOf(activeExtendedKey) : undefined}
                        options={extendedKeys}
                        onChange={(index) => setActiveExtendedKey(extendedKeys[index])}
                    />
                </DropdownButton>
            </Block>
        </Container>
    )
}

export default EditPanelMetadataJSON
