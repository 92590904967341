import { useContext } from 'react'
import Loader from 'ui/src/components/loader'
import { EditPanelContext } from '../../contexts/panel'
import { Container } from './style'

type Props = {
    className?: string
}

const EditPanelLoading = ({ className }: Props) => {
    const { colors } = useContext(EditPanelContext)

    return (
        <Container $backgroundColor={colors.backgroundColor} className={className}>
            <Loader size="medium" type="blocks" />
        </Container>
    )
}

export default EditPanelLoading
