import { ControlSize } from 'types/src/controls'

// buttons
export const ALERT_CANCEL_LABEL = 'Cancel'
export const ALERT_CONTINUE_LABEL = 'Continue'
export const SAVE_BUTTON_LABEL = 'Save'
export const SAVE_BUTTON_WIDTH = 84

// preview
export const MAX_PREVIEW_HEIGHT = 400
export const PREVIEW_WIDTH = 320
export const MEDIA_NAVIGATION_PREVIEW_HEIGHT = 80
export const MEDIA_NAVIGATION_PREVIEW_WIDTH = 100

// metadata
export const METADATA_LABEL_WIDTH = 60
export const METADATA_TIME_WIDTH = SAVE_BUTTON_WIDTH

// sizes
export const CONTROL_SIZE: ControlSize = 'medium'
export const CONTROL_SIZE_SLIM: ControlSize = 'small'
export const FOOTER_SIZE: ControlSize = 'small'
export const HEADER_SIZE: ControlSize = 'medium'
export const METADATA_INFO_SIZE: ControlSize = 'mini'

// spacing
export const CONTROL_SPACING: number = 6
export const CONTROL_SPACING_LARGE: number = 10
export const GRID_SPACING: number = 20
export const GRID_SPACING_LARGE: number = 30

// tooltips
export const TOOLTIP_WIDTH = 320
export const TOOLTIP_WIDTH_SMALL = 230
