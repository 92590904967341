import { useContext } from 'react'
import { HEADER_SIZE } from '../constants'
import { EditPanelContext } from '../contexts/panel'
import { editPanelTabs } from '../types/tab'
import { hasTabPermission } from '../utilities/permissions'
import { getTabs, labelFromTab } from '../utilities/tab'
import { Close, Header, Tabs } from './style'

type Props = {
    className?: string
    closeable?: boolean
    showCollections?: boolean
    onClose?: () => void
}

const EditPanelHeader = ({ className, closeable = false, showCollections = false, onClose }: Props) => {
    const { colors, tab, permissions, setTab } = useContext(EditPanelContext)
    const tabs = getTabs(showCollections).filter((t) => hasTabPermission(t, permissions))
    const tabLabels = tabs.map(labelFromTab)
    const tabIndex = tabs.indexOf(tab)

    return (
        <Header $color={colors.backgroundColor} className={className}>
            <Tabs
                activeColor={colors.header}
                activeBorderColor={colors.headerHighlight}
                color={colors.headerInactive}
                dividerColor={colors.divider}
                index={tabIndex}
                labels={tabLabels}
                size={HEADER_SIZE}
                onChange={(index) => setTab(editPanelTabs[index])}
            />
            {closeable && (
                <Close
                    $borderColor={colors.divider}
                    $hasBackground={!!colors.headerClose}
                    color={colors.headerClose || 'transparent'}
                    icon="close"
                    iconColor={colors.headerCloseIcon || colors.header}
                    size={HEADER_SIZE}
                    onClick={() => onClose?.()}
                />
            )}
        </Header>
    )
}

export default EditPanelHeader
