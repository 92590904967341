import { ReactNode, useContext, useState } from 'react'
import Collapsible from '../../../../controls/collapsible'
import TitleButton from '../../../components/button/title'
import { EditPanelContext } from '../../../contexts/panel'
import { Code, Content } from './style'
import { openJSONViewer } from './viewer'

type Props = {
    children?: ReactNode
    className?: string
    code?: Object | string
    placeholder?: string
    title: string
}

export default ({ children, className, code, placeholder = '', title }: Props) => {
    const { colors } = useContext(EditPanelContext)
    const [isOpen, setIsOpen] = useState<boolean>(true)
    const isObject = typeof code === 'object'

    function onOpenJSON() {
        code && openJSONViewer(code, title)
    }

    return (
        <Collapsible
            arrowColor={colors.labelArrow || colors.labelIcon}
            className={className}
            color={colors.label}
            defaultOpen
            iconColor={colors.labelIcon}
            title={title}
            titleComponent={
                !!code ? (
                    <TitleButton style={!isOpen ? { visibility: 'hidden' } : undefined} onClick={() => onOpenJSON()}>
                        Open in JSON Viewer
                    </TitleButton>
                ) : undefined
            }
            onToggle={setIsOpen}
        >
            <Content>
                {children}
                <Code
                    code={code || placeholder}
                    color={colors.control}
                    format={isObject ? 'json' : undefined}
                    resizeable
                />
            </Content>
        </Collapsible>
    )
}
