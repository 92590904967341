import { ReactNode, createContext } from 'react'
import { EditPanelAction } from '../types/action'
import { EditPanelField } from '../types/field'

export type EditPanelMessageContextProps = {
    showActionMessage: <T>(action: EditPanelAction, value: T) => void
    showFieldMessage: <T>(field: EditPanelField, value: T) => void
    showErrorMessage: (message: ReactNode, id?: string) => void
}

export const EditPanelMessageContext = createContext({} as EditPanelMessageContextProps)
