import { useContext, useMemo } from 'react'
import { CONTROL_SIZE_SLIM } from '../../../constants'
import { EditPanelContext } from '../../../contexts/panel'
import { Icon, Label, Time, Timestamp } from './style'

type Props = {
    className?: string
    label: string
    value?: string
}

export default ({ className, label, value = '' }: Props) => {
    const { colors, isCompact } = useContext(EditPanelContext)

    const date = useMemo(() => {
        if (!value || isNaN(Date.parse(value))) {
            return null
        }

        return new Date(value)
    }, [value])

    const [day, time] = useMemo(() => {
        if (!date) {
            return ['--', '']
        }

        const d = date.toLocaleDateString('en-US', {
            day: 'numeric',
            month: 'long',
            year: 'numeric',
        })

        const t = date.toLocaleTimeString('en-US', {
            hour: isCompact ? 'numeric' : '2-digit',
            minute: '2-digit',
        })

        if (isCompact) {
            return [`${d} ${t}`, undefined]
        }

        return [d, t]
    }, [date])

    return (
        <div className={className}>
            <Label $color={colors.label}>{label}</Label>
            <Timestamp $compact={isCompact || !time} color={colors.control} size={CONTROL_SIZE_SLIM} title={day}>
                {!!date && <Icon color={colors.labelIcon} icon="calendar" height={16} width={16} />}
                {day}
            </Timestamp>
            {time && (
                <Time color={colors.control} size={CONTROL_SIZE_SLIM} title={time}>
                    {time}
                </Time>
            )}
        </div>
    )
}
