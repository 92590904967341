import { ReactNode, useContext } from 'react'
import { PortalMessageContext } from 'ui/src/components/message/portal-message/context'
import ActionMessage from '../components/message/action'
import FieldMessage from '../components/message/field'
import { EditPanelMessageContext } from '../contexts/message'
import { EditPanelAction } from '../types/action'
import { EditPanelField } from '../types/field'
import { isInternalAction } from '../utilities/action'

const ID_PREFIX = 'edit-panel-message-'

const EditPanelMessageContextManager = ({ children }: { children: ReactNode }) => {
    const { showMessage } = useContext(PortalMessageContext)

    function showActionMessage<T>(action: EditPanelAction, value: T) {
        showMessage(<ActionMessage action={action} value={value} />, {
            id: `${ID_PREFIX}${action}`,
            style: isInternalAction(action) ? 'default' : 'success',
        })
    }

    function showFieldMessage<T>(field: EditPanelField, value: T) {
        showMessage(<FieldMessage field={field} value={value} />, {
            id: `${ID_PREFIX}${field}`,
            style: 'success',
        })
    }

    function showErrorMessage(message: ReactNode, id?: string) {
        showMessage(message, {
            id: id ? `${ID_PREFIX}${id}` : undefined,
            style: 'error',
        })
    }

    return (
        <EditPanelMessageContext.Provider value={{ showActionMessage, showFieldMessage, showErrorMessage }}>
            {children}
        </EditPanelMessageContext.Provider>
    )
}

export default EditPanelMessageContextManager
