import { IUser } from '@giphy/js-types'
import { createContext } from 'react'
import { Language } from 'types/src/language'
import { ExtendedMetadata, LanguageTag, Metadata, TagCounts } from '../../types'
import { EditPanelExternalAction } from '../types/action'
import { EditPanelField } from '../types/field'

export type EditPanelApiContextProps = {
    fetchExtendedMetadata: (gifId: string) => Promise<ExtendedMetadata>
    fetchLanguageTags: (gifIds: string[], language: Language) => Promise<LanguageTag[]>
    fetchMetadata: (gifId: string) => Promise<Metadata>
    fetchTagCounts: (gifId: string) => Promise<TagCounts>
    fetchUserByUsername: (username: string) => Promise<IUser>
    fetchViewCount: (gifId: string) => Promise<number>
    onAction: <T>(gifIds: string[], action: EditPanelExternalAction, value: T) => Promise<void>
    onChange: <T>(gifIds: string[], field: EditPanelField, value: T) => Promise<void>
}

export const EditPanelApiContext = createContext({} as EditPanelApiContextProps)
