import { Gradient } from 'types/src/gradient'

export const editPanelExternalActions = [
    'add-tags',
    'delete',
    'escalate',
    'explicit',
    'feature-tag',
    'queue',
    'remove-all-tags',
    'remove-tag',
    'repair',
    'reports',
    'schedule',
    'takedown',
    'trend',
    'unfeature-tag',
] as const
export type EditPanelExternalAction = (typeof editPanelExternalActions)[number]

export const editPanelInternalActions = ['copy-all-tags', 'copy-gif-id'] as const
export type EditPanelInternalAction = (typeof editPanelInternalActions)[number]

export type EditPanelAction = EditPanelExternalAction | EditPanelInternalAction

export type EditPanelActionAlert = {
    continueGradient?: Gradient
    continueLabel?: string
    description?: string
    title: string
}
