import { useMemo } from 'react'
import { isSameDay } from '../utilities/date'

export function useIsSameDay(d1: Date, d2?: Date): boolean {
    return useMemo(() => {
        if (d2) {
            return isSameDay(d1, d2)
        }

        return false
    }, [d1, d2])
}
