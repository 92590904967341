import { useContext } from 'react'
import { CONTROL_SIZE_SLIM } from '../../../constants'
import { EditPanelActionContext } from '../../../contexts/action'
import { EditPanelContext } from '../../../contexts/panel'
import { Copy, GifId, Label } from './style'

type Props = {
    className?: string
    label: string
    value: string
}

export default ({ className, label, value }: Props) => {
    const { colors } = useContext(EditPanelContext)
    const { performAction } = useContext(EditPanelActionContext)

    function onCopy() {
        performAction('copy-gif-id', value)
    }

    return (
        <div className={className}>
            <Label $color={colors.label}>{label}</Label>
            <GifId color={colors.control} readOnly size={CONTROL_SIZE_SLIM} value={value}>
                {navigator.clipboard && (
                    <Copy
                        color={colors.button}
                        size={CONTROL_SIZE_SLIM}
                        textColor={colors.buttonText}
                        onClick={() => onCopy()}
                    >
                        Copy
                    </Copy>
                )}
            </GifId>
        </div>
    )
}
