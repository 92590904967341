import { useMemo } from 'react'
import { Dimensions } from 'types/src/controls'
import { CASCADE_OFFSET } from '../cascade'

export function useImageDimensions(
    width: number,
    height: number,
    aspectRatio: number,
    isBulk: boolean = false
): Dimensions {
    return useMemo(() => {
        let w = width - (isBulk ? CASCADE_OFFSET : 0)
        let h = height - (isBulk ? CASCADE_OFFSET : 0)

        // if a tiny gap will show, just round height
        if (isBulk && w / aspectRatio - h < 12) {
            h = Math.round(w / aspectRatio)
        }

        return { height: h, width: w }
    }, [aspectRatio, height, isBulk, width])
}
