import { lazy, Suspense } from 'react'
import { ControlSize } from 'types/src/controls'
import Loader from 'ui/src/components/loader/blocks'
import { EditPanelField } from '../types/field'

const Checkbox = lazy(() => import('./inputs/checkbox'))
const Indexed = lazy(() => import('./inputs/indexed'))
const Privacy = lazy(() => import('./inputs/privacy'))
const Rating = lazy(() => import('./inputs/rating'))
const Source = lazy(() => import('./inputs/source'))
const Text = lazy(() => import('./inputs/text'))
const Username = lazy(() => import('./inputs/username'))

export type EditPanelFieldComponentProps = {
    className?: string
    field: EditPanelField
    gifId?: string
    size?: ControlSize
}

const EditPanelFieldComponent = (props: EditPanelFieldComponentProps) => {
    function getComponent() {
        switch (props.field) {
            case 'indexed':
                return <Indexed {...props} />
            case 'no-trend':
                return <Checkbox {...props} inverted />
            case 'privacy':
                return <Privacy {...props} />
            case 'rating':
                return <Rating {...props} />
            case 'source':
                return <Source {...props} />
            case 'username':
                return <Username {...props} />
            default:
                return <Text {...props} />
        }
    }

    return <Suspense fallback={<Loader size="small" />}>{getComponent()}</Suspense>
}

export default EditPanelFieldComponent
