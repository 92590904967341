import { ReactNode, useContext } from 'react'
import { Language } from 'types/src/language'
import { titleFromLanguage } from 'utils/src/language'
import { Tag } from '../../../../types'
import { CONTROL_SIZE } from '../../../constants'
import { EditPanelActionContext } from '../../../contexts/action'
import { EditPanelContext } from '../../../contexts/panel'
import { Container, Input } from './style'

type Props = {
    children?: ReactNode
    className?: string
    language?: Language
}

export default ({ children, className, language }: Props) => {
    const { colors, permissions } = useContext(EditPanelContext)
    const { performAction } = useContext(EditPanelActionContext)
    const placeholder = `Add Tags${language ? ` (${titleFromLanguage(language)})` : ''}`

    function onAdd(tagStrings: string[]) {
        const tags: Tag[] = tagStrings.map((text) => ({ language, text }))

        performAction('add-tags', tags)
    }

    return (
        <Container className={className}>
            <Input
                allowGiphyTags={permissions.staff}
                buttonColor={colors.button}
                buttonIconColor={colors.buttonText}
                color={colors.input}
                placeholder={placeholder}
                size={CONTROL_SIZE}
                onAdd={onAdd}
            >
                {children}
            </Input>
        </Container>
    )
}
