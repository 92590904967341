import { giphyYellow } from '@giphy/colors'
import { CSSProperties, ReactNode, SyntheticEvent, useContext } from 'react'
import styled from 'styled-components'
import IconComponent from 'ui/src/components/icon'
import Icon from 'ui/src/types/icons'
import { EditPanelContext } from '../../contexts/panel'

const Button = styled.button`
    all: unset;
    align-items: center;
    color: inherit;
    cursor: pointer;
    display: inline-flex;
    gap: 0.3em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:focus-visible {
        border-radius: 2px;
        outline: 2px solid ${giphyYellow};
    }

    &:active {
        opacity: 0.8;
    }
`

type Props = {
    children?: ReactNode
    className?: string
    href?: string
    icon?: Icon
    style?: CSSProperties
    onClick?: (e: SyntheticEvent<HTMLElement, MouseEvent>) => void
}

const EditPanelTitleButton = ({ children, className, href, icon = 'navigate-right', style, onClick }: Props) => {
    const { colors } = useContext(EditPanelContext)

    return (
        <Button
            as={href ? 'a' : undefined}
            className={className}
            href={href}
            style={style}
            target={href ? '_blank' : undefined}
            onClick={onClick}
        >
            {children}
            <IconComponent color={colors.labelIcon} height={14} icon={icon} width={14} />
        </Button>
    )
}

export default EditPanelTitleButton
