import { Language, allLanguages } from 'types/src/language'

export function flagFromLanguage(language: Language): string {
    return valuesFromLanguage(language)[0]
}

export function languageFromString(string: string): Language | undefined {
    const code = string.toLowerCase().trim()
    return allLanguages.find((lang) => lang === code)
}

export function labelFromLanguage(language: Language): string {
    return `${titleFromLanguage(language)} (${language})`
}

export function titleFromLanguage(language: Language): string {
    return valuesFromLanguage(language)[1]
}

function valuesFromLanguage(language: Language): [string, string] {
    switch (language) {
        case 'ar':
            return ['🇸🇦', 'Arabic']
        case 'bn':
            return ['🇧🇩', 'Bengali']
        case 'cs':
            return ['🇨🇿', 'Czech']
        case 'da':
            return ['🇩🇰', 'Danish']
        case 'de':
            return ['🇩🇪', 'German']
        case 'en':
            return ['🇬🇧', 'English']
        case 'es':
            return ['🇪🇸', 'Spanish']
        case 'fa':
            return ['🇮🇷', 'Farsi']
        case 'fi':
            return ['🇫🇮', 'Finnish']
        case 'fr':
            return ['🇫🇷', 'French']
        case 'hi':
            return ['🇮🇳', 'Hindi']
        case 'hu':
            return ['🇭🇺', 'Hungarian']
        case 'id':
            return ['🇮🇩', 'Indonesian']
        case 'it':
            return ['🇮🇹', 'Italian']
        case 'iw':
            return ['🇮🇱', 'Hebrew']
        case 'ja':
            return ['🇯🇵', 'Japanese']
        case 'ko':
            return ['🇰🇷', 'Korean']
        case 'ms':
            return ['🇲🇾', 'Malay']
        case 'nl':
            return ['🇳🇱', 'Dutch']
        case 'no':
            return ['🇳🇴', 'Norwegian']
        case 'pl':
            return ['🇵🇱', 'Polish']
        case 'pt':
            return ['🇵🇹', 'Portuguese']
        case 'ro':
            return ['🇷🇴', 'Romanian']
        case 'ru':
            return ['🇷🇺', 'Russian']
        case 'sv':
            return ['🇸🇪', 'Swedish']
        case 'th':
            return ['🇹🇭', 'Thai']
        case 'tl':
            return ['🇵🇭', 'Tagalog']
        case 'tr':
            return ['🇹🇷', 'Turkish']
        case 'uk':
            return ['🇺🇦', 'Ukranian']
        case 'vi':
            return ['🇻🇳', 'Vietnamese']
        case 'zh-CN':
            return ['🇨🇳', 'Chinese Simplified']
        case 'zh-TW':
            return ['🇹🇼', 'Chinese Traditional']
    }
}
