import { Fragment, useContext, useMemo } from 'react'
import { EditPanelContext } from '../../contexts/panel'
import { Divider } from '../../style'
import { defaultOpenFromField, supportedContentTypesFromField } from '../../utilities/field'
import { sharedContentTypeFromGifs } from '../../utilities/gifs'
import CollapsibleField from './collapsible'
import { Container } from './style'

type Props = {
    className?: string
}

const EditPanelInfoFields = ({ className }: Props) => {
    const { colors, fields, gifs, isCompact } = useContext(EditPanelContext)
    const contentType = sharedContentTypeFromGifs(gifs)

    const filteredFields = useMemo(() => {
        return fields.filter((field) => {
            const supported = supportedContentTypesFromField(field)

            return supported.indexOf(contentType) > -1
        })
    }, [contentType, fields])

    return (
        <Container className={className}>
            {filteredFields.map((field, i) => {
                const defaultOpen = defaultOpenFromField(field) || filteredFields.length < 3

                return (
                    <Fragment key={`edit-panel-field-${field}-${i}`}>
                        <CollapsibleField defaultOpen={defaultOpen} field={field} />
                        {isCompact && field === 'description' && <Divider $color={colors.divider} />}
                    </Fragment>
                )
            })}
        </Container>
    )
}

export default EditPanelInfoFields
