import { useContext } from 'react'
import { textColorFromBackgroundColor } from '../../../../utils/color'
import { CalendarContext } from '../context'
import { useIsNextDisabled } from '../hooks/use-is-next-disabled'
import { useIsPreviousDisabled } from '../hooks/use-is-previous-disabled'
import { Button, Header, Title } from './style'

type Props = {
    className?: string
    fontFamily?: string
    fontSize: number
}

const CalendarHeader = ({ className, fontFamily, fontSize }: Props) => {
    const { colors, max, min, visible, visibleMonth, setVisible } = useContext(CalendarContext)
    const isNextDisabled = useIsNextDisabled(visibleMonth.dates, max)
    const isPreviousDisabled = useIsPreviousDisabled(visibleMonth.dates, min)

    const iconColor = textColorFromBackgroundColor(colors.button)
    const month = visible.toLocaleString('default', { month: 'short' })
    const year = visible.getFullYear()

    function nextMonth() {
        const next = new Date(visible)
        next.setMonth(next.getMonth() + 1)
        setVisible(next)
    }

    function previousMonth() {
        const prev = new Date(visible)
        prev.setMonth(prev.getMonth() - 1)
        setVisible(prev)
    }

    return (
        <Header className={className}>
            <Title style={{ color: colors.header, fontFamily, fontSize }}>
                {month} {year}
            </Title>
            <Button
                color={colors.button}
                disabled={isPreviousDisabled}
                icon="navigate-left"
                iconColor={iconColor}
                size="mini"
                onClick={() => previousMonth()}
            />
            <Button
                color={colors.button}
                disabled={isNextDisabled}
                icon="navigate-right"
                iconColor={iconColor}
                size="mini"
                onClick={() => nextMonth()}
            />
        </Header>
    )
}

export default CalendarHeader
