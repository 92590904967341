import { giphyUrl } from '@/app/util/env'
import { GET } from '@/app/util/url'
import { IChannel } from '@giphy/js-types'

const API_URL = `${giphyUrl}/api/v4/channels`

export const fetchChannelByUsername = async (username: string): Promise<IChannel> => {
    const f = await fetch(`${API_URL}?slug=${username}`, GET())
    return await f.json()
}
