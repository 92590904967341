import styled from 'styled-components'
import { ControlSize } from 'types/src/controls'
import { heightFromControlSize } from 'utils/src/control-size'
import TextInput from '../../text-input'

export const Input = styled(TextInput)<{ $size: ControlSize }>`
    font-weight: bold;
    margin: 0;
    padding: 0;
    width: ${({ $size }) => heightFromControlSize($size)}px;

    input {
        padding: 0;
        text-align: center;
    }
`
