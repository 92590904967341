import { useContext } from 'react'
import { PortalTooltipContext } from '../portal-tooltip/context'

export type GlobalTooltipHookProps = {
    activeId?: string
    closeTooltip: () => void
}

export function useGlobalTooltip() {
    const { activeId, closeTooltip } = useContext(PortalTooltipContext)

    return { activeId, closeTooltip }
}
