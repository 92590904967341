export const OBJECTS_REGEX = /{([^}]*)}/g
export const URLS_REGEX = /((http|https|ftp):\/\/[\w?=&.\/-;#~%-]+(?![\w\s?&.\/;#~%"=-]*>))/g

export function previewFromObject(object: Object): string {
    let p = JSON.stringify(object, null, 2)

    p = p.substring(1, p.length - 2) // remove first {} or []
    p = p.replace(URLS_REGEX, '...') // urls
    p = p.replace(OBJECTS_REGEX, '{...}') // objects

    return `${prefixFromObject(object)} ${p} ${suffixFromObject(object)}`
}

export function prefixFromObject(object: Object): string {
    return Array.isArray(object) ? '[' : '{'
}

export function suffixFromObject(object: Object): string {
    return Array.isArray(object) ? ']' : '}'
}
