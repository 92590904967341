import { IGif } from '@giphy/js-types'
import { BulkLanguageTags, LanguageTag } from '../../types'
import { EditPanelTag } from '../types/tag'

export function tagsFromLanguageTags(languageTags: LanguageTag[], gifIds: string[]): EditPanelTag[] {
    const allTags = languageTags.flatMap((tagGroup) => {
        if ('tags' in tagGroup && Array.isArray((tagGroup as BulkLanguageTags).tags)) {
            return (tagGroup as BulkLanguageTags).tags
        }
        return []
    })

    const tagStrings = allTags.map((t) => t.text)

    return allTags
        .filter((t, i) => tagStrings.indexOf(t.text) === i)
        .sort((a, b) => a.text.localeCompare(b.text))
        .map((tag) => ({
            featured: false,
            shared:
                gifIds.length > 1 &&
                gifIds.every(
                    (id) =>
                        allTags.findIndex((t) => t.gifId === id) > -1 &&
                        allTags.findIndex((t) => t.text === tag.text) > -1
                ),
            text: tag.text || '',
        })) as EditPanelTag[]
}

export function tagStringsFromGifs(gifs: IGif[], allowGiphyTags: boolean = false): string[] {
    const tags: string[] = []

    gifs.forEach((gif) => {
        gif.tags.forEach((tag) => {
            if (allowGiphyTags || tag.indexOf('giphy') !== 0) {
                if (tags.indexOf(tag) < 0) {
                    tags.push(tag)
                }
            }
        })
    })

    return tags.sort()
}

export function tagsFromGifs(gifs: IGif[], allowGiphyTags: boolean = false): EditPanelTag[] {
    const tags = tagStringsFromGifs(gifs, allowGiphyTags)

    return tags.map((text) => ({
        featured: gifs.every((g: any) => (g.featured_tags || []).indexOf(text) > -1),
        shared: gifs.length > 1 && gifs.every((g) => g.tags.indexOf(text) > -1),
        text,
    }))
}
