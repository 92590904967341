export function applyTimeToDate(date: Date, time: string): Date {
    const segments = time.split(':')
    const h = parseInt(segments[0])
    const m = parseInt(segments[1])

    !isNaN(h) && date.setHours(h)
    !isNaN(m) && date.setMinutes(m)

    return date
}

export function dateToTime(date: Date): string {
    const hours = date.getHours()
    const minutes = date.getMinutes()
    const h = `${hours}`.padStart(2, '0')
    const m = `${minutes}`.padStart(2, '0')
    return `${h}:${m}`
}

export function dateWithNoTime(date: Date = new Date()): Date {
    const newDate = new Date(date)
    newDate.setHours(0, 0, 0, 0)
    return newDate
}

export function dateWithTime(date: Date, time: string): Date {
    const d = new Date(date)

    return applyTimeToDate(d, time)
}

export function isSameDay(date1: Date, date2: Date): boolean {
    const d1 = dateWithNoTime(date1)
    const d2 = dateWithNoTime(date2)

    return d1.getTime() === d2.getTime()
}
