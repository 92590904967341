import { useContext, useState } from 'react'
import { useAsyncEffect } from 'utils'
import { Metadata } from '../../types'
import { MEDIA_NAVIGATION_PREVIEW_HEIGHT, MEDIA_NAVIGATION_PREVIEW_WIDTH } from '../constants'
import { EditPanelApiContext } from '../contexts/api'
import { EditPanelContext } from '../contexts/panel'
import { Divider } from '../style'
import Fields from './fields'
import Info from './info'
import JSON from './json'
import { Navigation } from './style'
import Timestamps from './timestamps'

type Props = {
    className?: string
}

const EditPanelMetadata = ({ className }: Props) => {
    const { fetchMetadata } = useContext(EditPanelApiContext)
    const { colors, fields, gifIds, gifs } = useContext(EditPanelContext)
    const [activeGifIndex, setActiveGifIndex] = useState<number>(0)
    const [metadata, setMetadata] = useState<Metadata>('')

    useAsyncEffect(async () => {
        const data = await fetchMetadata(gifIds[activeGifIndex])
        setMetadata(data)
    }, [activeGifIndex])

    return (
        <div className={className}>
            {gifs.length > 1 && (
                <Navigation
                    arrowColor={colors.button}
                    arrowIconColor={colors.buttonText}
                    backgroundColor={colors.controlDark}
                    gifs={gifs}
                    index={activeGifIndex}
                    previewHeight={MEDIA_NAVIGATION_PREVIEW_HEIGHT}
                    previewWidth={MEDIA_NAVIGATION_PREVIEW_WIDTH}
                    onChange={setActiveGifIndex}
                />
            )}
            <Timestamps metadata={metadata} />
            <Divider $color={colors.divider} />
            <Info gifId={gifIds[activeGifIndex]} />
            <Divider $color={colors.divider} />
            {fields.length > 0 && (
                <>
                    <Fields gifId={gifIds[activeGifIndex]} />
                    <Divider $color={colors.divider} />
                </>
            )}
            <JSON gifId={gifIds[activeGifIndex]} metadata={metadata} />
        </div>
    )
}

export default EditPanelMetadata
