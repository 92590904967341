export type TimeInputSegments = {
    hours: number
    minutes: number
    isPM: boolean
}

export function cleanString(time: string): string {
    const segments = time.split(':')
    let hours = 0
    let minutes = 0

    if (segments.length > 1) {
        hours = parseInt(segments[0])
        minutes = parseInt(segments[1])
    }

    hours = isNaN(hours) ? 0 : hours
    minutes = isNaN(minutes) ? 0 : minutes

    const h = `${hours}`.padStart(2, '0')
    const m = `${minutes}`.padStart(2, '0')

    return `${h}:${m}`
}

export function segmentsFromString(time: string): TimeInputSegments {
    const string = cleanString(time)
    const segments = string.split(':')
    const hours = parseInt(segments[0])
    const minutes = parseInt(segments[1])

    return {
        hours: hours % 12,
        minutes,
        isPM: hours >= 12,
    }
}

export function stringFromSegments(segments: TimeInputSegments): string {
    const { hours, minutes, isPM } = segments
    let h = hours === 12 ? 0 : hours
    h += isPM ? 12 : 0

    return `${`${h}`.padStart(2, '0')}:${`${minutes}`.padStart(2, '0')}`
}
