import { giphyBlack } from '@giphy/colors'
import { ReactNode, useContext } from 'react'
import { ControlSize } from 'types/src/controls'
import DropdownButton from 'ui/src/components/controls/dropdown-button'
import { brightnessFromColor } from 'utils/src/color'
import { TOOLTIP_WIDTH } from '../../../constants'
import { EditPanelContext } from '../../../contexts/panel'

type Props = {
    children?: ReactNode
    className?: string
    label: ReactNode
    size: ControlSize
}

const EditPanelDropdownButton = ({ children, className, label, size }: Props) => {
    const { colors } = useContext(EditPanelContext)
    const textColor = brightnessFromColor(colors.control) > 0.5 ? giphyBlack : 'inherit'

    return (
        <DropdownButton
            className={className}
            color={colors.control}
            dropdownOptions={{
                color: colors.tooltip,
                width: TOOLTIP_WIDTH,
            }}
            iconButtonOptions={{
                color: colors.button,
                iconColor: colors.buttonText,
            }}
            label={label}
            size={size}
            textColor={textColor}
        >
            {children}
        </DropdownButton>
    )
}

export default EditPanelDropdownButton
