import { giphyUrl } from '@/app/util/env'
import { Language } from 'types/src/language'
import { POST } from './gif'

export const addLanguageTags = async (gifId: string, tags: string[], language: Language) => {
    const formData = new FormData()

    formData.append('lang', language.toUpperCase().replace('-', '_'))

    tags.forEach((tag) => formData.append('tags[]', tag))

    const f = await fetch(`${giphyUrl}/ajax/gif/${gifId}/language-tags`, POST(formData))

    return await f.json()
}

export const removeLanguageTag = async (gifId: string, tag: string, language: Language) => {
    const f = await fetch(
        `${giphyUrl}/ajax/gif/${gifId}/language-tags?tag=${encodeURIComponent(tag)}&lang=${language
            .toUpperCase()
            .replace('-', '_')}`,
        { ...POST({}), method: 'DELETE' }
    )

    return f.json()
}
