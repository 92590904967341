import { GifDetails } from '@/api/gif'
import { useMemo } from 'react'
import { GifPermissions } from 'types'

const DEFAULT_PERMISSIONS: GifPermissions = {
    channel: false,
    delete: false,
    edit: false,
    hide: false,
    rate: false,
    tag: false,
    view: false,
    feature_tags: false,
    extended_metadata: false,
    tag_counts: false,
    can_download: false,
}

export function useGifPermissions(gifDetails: GifDetails[]) {
    return useMemo(() => {
        if (!gifDetails.length) {
            return DEFAULT_PERMISSIONS
        }

        const permissions = gifDetails.map((d) => d.permissions)
        const merged = {}
        const keys = Object.keys(DEFAULT_PERMISSIONS)

        keys.forEach((key) => {
            ;(merged as any)[key] = permissions.every((p) => (p as any)[key] === true)
        })

        return merged as GifPermissions
    }, [gifDetails])
}
