import { giphyYellow } from '@giphy/colors'
import styled, { css } from 'styled-components'
import { ControlSize } from 'types/src/controls'
import PillComponent from 'ui/src/components/controls/pill'
import { borderRadiusFromControlSize, heightFromControlSize } from 'utils/src/control-size'

export const Clickable = styled.span<{ $removeable: boolean; $size: ControlSize }>`
    align-items: center;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    user-select: none;

    ${({ $removeable, $size }) => {
        return css`
            border-radius: ${borderRadiusFromControlSize($size)}px;
            height: ${heightFromControlSize($size)}px;
            padding: 0 ${$removeable ? 0 : 0.8}em 0 0.8em;
        `
    }}

    ${({ $removeable }) =>
        $removeable &&
        css`
            border-bottom-right-radius: 0;
            border-top-right-radius: 0;
        `}
`

export const Count = styled.span`
    display: inline-block;
    font-size: 13px;
    margin-left: 0.4em;
    opacity: 0.5;
    pointer-events: none;
    user-select: none;
`

export const Hash = styled.span`
    display: inline-block;
    margin-right: 0.2em;
    opacity: 0.5;
    pointer-events: none;
    user-select: none;
    vertical-align: middle;
`

export const Pill = styled(PillComponent)<{ $clickable: boolean; $highlighted: boolean; $highlightColor: string }>`
    ${({ $highlighted, $highlightColor }) =>
        $highlighted &&
        css`
            box-shadow: inset 0 0 0 2px ${$highlightColor};
        `}

    ${({ $clickable }) =>
        $clickable &&
        css`
            padding: 0;

            &:has(${Clickable}:focus-visible) {
                outline: 2px solid ${giphyYellow};
            }

            &:has(${Clickable}:active) {
                opacity: 0.8;
            }

            button:active {
                opacity: 0.6;
            }
        `}
`
