import { createContext } from 'react'
import { EditPanelAction } from '../types/action'
import { EditPanelColors } from '../types/colors'
import { EditPanelField } from '../types/field'
import { EditPanelGif } from '../types/gif'
import { EditPanelPermissions } from '../types/permissions'
import { EditPanelTab } from '../types/tab'

export type EditPanelContextProps = {
    actions: EditPanelAction[]
    colors: EditPanelColors
    fields: EditPanelField[]
    gifs: EditPanelGif[]
    gifIds: string[]
    isCompact: boolean
    permissions: EditPanelPermissions
    tab: EditPanelTab
    width: number
    setTab: (tab: EditPanelTab) => void
}

export const EditPanelContext = createContext({} as EditPanelContextProps)
