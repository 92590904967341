import styled from 'styled-components'
import IconButton from 'ui/src/components/controls/icon-button'
import { CASCADE_SIZE } from '../../cascade'

export const Mute = styled(IconButton)`
    filter: drop-shadow(0px 3px 6px rgba(33, 37, 41, 0.7));
    left: ${CASCADE_SIZE}px;
    position: absolute;
    top: ${CASCADE_SIZE}px;
`

export const Video = styled.video`
    border-radius: inherit;
    display: block;
    height: 100%;
    pointer-events: none;
    width: 100%;
`

export const Wrapper = styled.div`
    border-radius: inherit;
    position: relative;
`
