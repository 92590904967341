export const allLanguages = [
    'en',
    'ko',
    'pt',
    'ru',
    'es',
    'ja',
    'ar',
    'bn',
    'zh-CN',
    'zh-TW',
    'cs',
    'da',
    'nl',
    'fa',
    'fi',
    'fr',
    'de',
    'iw',
    'hi',
    'hu',
    'id',
    'it',
    'ms',
    'no',
    'pl',
    'ro',
    'sv',
    'th',
    'tl',
    'tr',
    'uk',
    'vi',
] as const
export type Language = (typeof allLanguages)[number]
