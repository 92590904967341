import { createContext } from 'react'
import { EditPanelField } from '../types/field'

export type EditPanelFieldContextProps = {
    isCtaOn: boolean
    changeField: <T>(field: EditPanelField, value: T) => Promise<void>
    setIsCtaOn: (isOn: boolean) => void
}

export const EditPanelFieldContext = createContext({} as EditPanelFieldContextProps)
