import {
    giphyDarkCharcoal,
    giphyDarkGrey,
    giphyDarkestGrey,
    giphyIndigo,
    giphyLightCharcoal,
    giphyLightGrey,
    giphyLightestGrey,
    giphyYellow,
} from '@giphy/colors'
import { EditPanelColors } from '../types/colors'

export const DEFAULT_COLORS: Partial<EditPanelColors> = {
    badgeColor: giphyYellow,
    button: giphyDarkCharcoal,
    buttonText: giphyLightestGrey,
    control: giphyDarkGrey,
    divider: giphyDarkCharcoal,
    footer: giphyLightestGrey,
    footerEllipsis: giphyLightGrey,
    footerIcon: giphyLightCharcoal,
    gradient: 'indigo-purple',
    header: '#fff',
    input: '#fff',
    label: giphyLightGrey,
    labelIcon: giphyLightCharcoal,
    sharedTag: giphyIndigo,
    text: giphyLightestGrey,
    tooltip: giphyDarkestGrey,
}
