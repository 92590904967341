import { Gradient } from 'types/src/gradient'
import Icon from 'ui/src/types/icons'
import { EditPanelAction, editPanelInternalActions } from '../types/action'

export function gradientFromAction(action: EditPanelAction): Gradient | undefined {
    switch (action) {
        case 'delete':
            return 'red-pink'
        case 'queue':
            return 'red-yellow'
        case 'schedule':
            return 'blue-cyan'
        case 'trend':
            return 'blue-green'
        default:
            return
    }
}

export function iconFromAction(action: EditPanelAction): Icon {
    switch (action) {
        case 'add-tags':
        case 'feature-tag':
        case 'remove-all-tags':
        case 'remove-tag':
        case 'unfeature-tag':
            return 'tag'
        case 'copy-all-tags':
        case 'copy-gif-id':
            return 'duplicate'
        case 'delete':
            return 'trash'
        case 'escalate':
            return 'question'
        case 'explicit':
        case 'reports':
            return 'flag'
        case 'queue':
            return 'dragger'
        case 'repair':
            return 'nav-gear'
        case 'schedule':
            return 'calendar'
        case 'takedown':
            return 'important'
        case 'trend':
            return 'add'
    }
}

export function isInternalAction(action: EditPanelAction): boolean {
    return editPanelInternalActions.indexOf(action as any) > -1
}

export function titleFromAction(action: EditPanelAction): string {
    switch (action) {
        case 'add-tags':
            return 'Add Tags'
        case 'copy-all-tags':
            return 'Copy all Tags'
        case 'copy-gif-id':
            return 'Copy GIF ID'
        case 'delete':
            return 'Delete'
        case 'escalate':
            return 'Escalate'
        case 'explicit':
            return 'Mark as Explicit'
        case 'feature-tag':
            return 'Feature Tag'
        case 'queue':
            return 'Queue'
        case 'remove-all-tags':
            return 'Remove all Tags'
        case 'remove-tag':
            return 'Remove Tag'
        case 'repair':
            return 'Repair Media'
        case 'reports':
            return 'View Reports'
        case 'schedule':
            return 'Schedule'
        case 'takedown':
            return 'Takedown'
        case 'trend':
            return 'Trend'
        case 'unfeature-tag':
            return 'Unfeature Tag'
    }
}
