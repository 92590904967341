import { giphyDarkGrey } from '@giphy/colors'
import { CSSProperties, useMemo } from 'react'
import JSONBlock from './json'
import { Block } from './style'

export const codeBlockFormats = ['json'] as const
export type CodeBlockFormat = (typeof codeBlockFormats)[number]

type Props = {
    code: Object | string
    color?: string
    className?: string
    format?: CodeBlockFormat
    resizeable?: boolean
    style?: CSSProperties
    textColor?: string
}

const CodeBlock = ({ code, color = giphyDarkGrey, className, format, resizeable = false, style, textColor }: Props) => {
    const codeString = useMemo(() => (typeof code === 'string' ? code : JSON.stringify(code, null, 4)), [code])

    function getComponent() {
        switch (format) {
            case 'json':
                return <JSONBlock code={codeString} />
            default:
                return codeString
        }
    }

    return (
        <Block $color={color} $resizeable={resizeable} $textColor={textColor} className={className} style={style}>
            {getComponent()}
        </Block>
    )
}

export default CodeBlock
