import styled, { css } from 'styled-components'
import { CONTROL_SPACING_LARGE, GRID_SPACING } from '../../constants'

export const Grid = styled.div<{ $compact: boolean }>`
    gap: ${CONTROL_SPACING_LARGE}px ${GRID_SPACING}px;

    ${({ $compact }) =>
        $compact
            ? css`
                  display: flex;
                  flex-direction: column;
              `
            : css`
                  display: grid;
                  grid-template-columns: repeat(2, 1fr);
              `}
`
