import { giphyDarkGrey } from '@giphy/colors'
import { CSSProperties, ReactNode, useEffect, useMemo, useRef, useState } from 'react'
import { ControlSize } from 'types'
import Input from './input'
import { Container, Time, Toggle } from './style'
import { cleanString, segmentsFromString, stringFromSegments } from './utilities'

type Props = {
    children?: ReactNode
    className?: string
    color?: string
    defaultValue?: string
    disabled?: boolean
    size?: ControlSize
    style?: CSSProperties
    toggleColor?: string
    value?: string
    onChange?: (time: string) => void
}

const TimeInput = ({
    children,
    className,
    color = giphyDarkGrey,
    defaultValue: defaultValueProp = '00:00',
    disabled = false,
    size = 'small',
    style,
    toggleColor = '#fff',
    value: valueProp,
    onChange,
}: Props) => {
    const defaultValue = typeof valueProp !== 'undefined' ? valueProp : defaultValueProp
    const initialValueRef = useRef<string | undefined>(cleanString(defaultValue))
    const initialSegments = segmentsFromString(initialValueRef.current || '00:00')

    const [hours, setHours] = useState<number>(initialSegments.hours)
    const [minutes, setMinutes] = useState<number>(initialSegments.minutes)
    const [isPM, setIsPM] = useState<boolean>(initialSegments.isPM)

    const value = useMemo(() => stringFromSegments({ hours, minutes, isPM }), [hours, minutes, isPM])
    const displayHours = hours === 0 ? 12 : hours

    useEffect(() => {
        if (typeof valueProp !== 'undefined' && valueProp !== value) {
            const segments = segmentsFromString(valueProp)
            setHours(segments.hours)
            setMinutes(segments.minutes)
            setIsPM(segments.isPM)
        }
    }, [valueProp])

    useEffect(() => {
        if (value !== initialValueRef.current) {
            initialValueRef.current = undefined
            onChange?.(value)
        }
    }, [value])

    return (
        <Container $disabled={disabled} className={className} style={style}>
            <Time $size={size}>
                <Input
                    color={color}
                    disabled={disabled}
                    max={12}
                    min={1}
                    size={size}
                    value={displayHours}
                    onChange={(h) => setHours(h === 12 ? 0 : h)}
                />
                :
                <Input color={color} disabled={disabled} size={size} value={minutes} onChange={setMinutes} />
            </Time>
            <Toggle
                backgroundColor="transparent"
                colorOff={toggleColor}
                colorOn={toggleColor}
                isOn={isPM}
                labelOff="AM"
                labelOn="PM"
                size="mini"
                tabIndex={disabled ? -1 : undefined}
                onChange={setIsPM}
            />
            {children}
        </Container>
    )
}

export default TimeInput
