import { IGif } from '@giphy/js-types'
import { MAX_PREVIEW_HEIGHT, PREVIEW_WIDTH } from '../constants'

export function getPreviewHeight(gif: IGif, width: number): number {
    const aspectRatio = gif.images.original.width / gif.images.original.height
    let height = Math.min(MAX_PREVIEW_HEIGHT, Math.round(width / aspectRatio))
    const w = Math.round(height * aspectRatio)

    // if almost width, just round up
    if (width - w < 20) {
        height = Math.round(PREVIEW_WIDTH / aspectRatio)
    }

    return height
}

export function getViewString(count: number, compact?: boolean): string {
    return new Intl.NumberFormat('en-US', {
        notation: compact ? 'compact' : 'standard',
        maximumFractionDigits: compact ? 1 : undefined,
    }).format(count)
}
