import { useContext, useState } from 'react'
import { Language } from 'types/src/language'
import Collapsible from '../../../controls/collapsible'
import { EditPanelContext } from '../../contexts/panel'
import { hasActionPermission } from '../../utilities/permissions'
import Input from './input'
import Languages from './languages'
import Pills from './pills'

type Props = {
    className?: string
}

const EditPanelInfoTags = ({ className }: Props) => {
    const { colors, permissions } = useContext(EditPanelContext)
    const [language, setLanguage] = useState<Language | undefined>()
    const showLanguageTags = permissions.languageTags || permissions.staff

    if (!hasActionPermission('add-tags', permissions)) {
        return null
    }

    return (
        <Collapsible
            arrowColor={colors.labelArrow || colors.labelIcon}
            className={className}
            color={colors.label}
            defaultOpen
            icon="tag"
            iconColor={colors.labelIcon}
            title="Tags"
        >
            <Input language={language}>{showLanguageTags && <Languages onChange={setLanguage} />}</Input>
            <Pills language={language} />
        </Collapsible>
    )
}

export default EditPanelInfoTags
