import styled, { css } from 'styled-components'
import Button from 'ui/src/components/controls/button'
import Pill from 'ui/src/components/controls/pill'
import TextInput from 'ui/src/components/controls/text-input'
import IconComponent from 'ui/src/components/icon'
import { METADATA_TIME_WIDTH } from '../../../constants'

export const Copy = styled(Button)`
    width: ${METADATA_TIME_WIDTH}px;
`

export const GifId = styled(TextInput)`
    grid-column: span 2;
`

export const Icon = styled(IconComponent)`
    display: inline-block;
    margin-right: 0.5em;
    vertical-align: top;
`

export const Label = styled.span<{ $color: string }>`
    align-items: center;
    color: ${({ $color }) => $color};
    display: flex;
    font-size: 13px;
    font-weight: bold;
`

export const Time = styled(Pill)`
    text-align: center;
`

export const Timestamp = styled(Pill)<{ $compact: boolean }>`
    ${({ $compact }) =>
        $compact &&
        css`
            grid-column: span 2;
        `}
`
