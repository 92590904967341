import { NestedKeyOf } from 'types/src/nested-key'
import Icon from 'ui/src/types/icons'
import { ContentType, contentTypes } from '../../types'
import { EditPanelField } from '../types/field'
import { EditPanelGif } from '../types/gif'

export function defaultOpenFromField(field: EditPanelField): boolean {
    switch (field) {
        case 'content-url':
        case 'no-trend':
        case 'rating':
        case 'slug':
        case 'source-body':
        case 'source-caption':
        case 'username':
            return true
        default:
            return false
    }
}

export function displayInlineFromField(field: EditPanelField, isCompact: boolean = false): boolean {
    switch (field) {
        case 'no-trend':
            return true
        case 'rating':
            return !isCompact
        default:
            return false
    }
}

export function iconFromField(field: EditPanelField): Icon | undefined {
    switch (field) {
        case 'alt-text':
            return 'caption'
        case 'description':
            return 'align-left'
        case 'no-trend':
            return 'search'
        case 'privacy':
            return 'view'
        case 'rating':
            return 'important'
        case 'source':
            return 'source'
        case 'title':
            return 'title'
        case 'username':
            return 'user'
        default:
            return
    }
}

export function supportedContentTypesFromField(field: EditPanelField): ContentType[] {
    switch (field) {
        case 'cta':
        case 'description':
        case 'title':
            return ['clip']
        default:
            return [...contentTypes]
    }
}

export function titleFromField(field: EditPanelField): string {
    switch (field) {
        case 'alt-text':
            return 'Alt Text'
        case 'content-url':
            return 'Content URL'
        case 'cta':
            return 'Button Title'
        case 'description':
            return 'Description'
        case 'indexed':
            return 'Is Indexed'
        case 'no-trend':
            return 'Eligible for Trending'
        case 'privacy':
            return 'Privacy'
        case 'rating':
            return 'Rating'
        case 'slug':
            return 'Slug'
        case 'source':
            return 'Source'
        case 'source-body':
            return 'Source Body'
        case 'source-caption':
            return 'Source Caption'
        case 'title':
            return 'Title'
        case 'username':
            return 'Username'
    }
}

export function valueKeyFromField(field: EditPanelField): NestedKeyOf<EditPanelGif> {
    switch (field) {
        case 'alt-text':
            return 'alt_text'
        case 'content-url':
            return 'source_content_url'
        case 'cta':
            return 'cta.text'
        case 'description':
            return 'description'
        case 'indexed':
            return 'is_indexable'
        case 'no-trend':
            return 'gps_no_trend'
        case 'privacy':
            return 'is_hidden'
        case 'rating':
            return 'rating'
        case 'slug':
            return 'slug'
        case 'source':
            return 'source_post_url'
        case 'source-body':
            return 'source_body'
        case 'source-caption':
            return 'source_caption'
        case 'title':
            return 'title'
        case 'username':
            return 'username'
    }
}
