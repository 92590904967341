import { updateGifs } from '@/api/edit/gif'
import { addTags, removeTag } from '@/api/edit/tag'
import { queueGifsToTrend, trendGifs } from '@/api/edit/trending'
import { TRENDING_QUEUE_TAG } from 'edit/constants'
import { EditPanelExternalAction } from 'edit/panel/types/action'
import { EditPanelGif } from 'edit/panel/types/gif'
import { Tag } from 'edit/types'
import { scheduleGifsToTrend } from '../../api/trending-schedule'

export function getEditPanelActionChanges<T>(
    gif: EditPanelGif,
    action: EditPanelExternalAction,
    value: T
): Partial<EditPanelGif> {
    switch (action) {
        case 'add-tags':
            return {
                tags: [
                    ...gif.tags,
                    ...(value as Tag[]).filter((t) => !t.language || t.language === 'en').map((t) => t.text),
                ],
            }
        case 'feature-tag':
            return {
                featured_tags: [...gif.featured_tags, value as string],
            }
        case 'queue':
        case 'schedule':
            return { tags: [...gif.tags, TRENDING_QUEUE_TAG] }
        case 'remove-tag':
            return { tags: gif.tags.filter((t) => t !== (value as Tag).text) }
        case 'trend':
            return { tags: [...gif.tags.filter((t) => t !== TRENDING_QUEUE_TAG)] }
        case 'unfeature-tag':
            return {
                featured_tags: gif.featured_tags.filter((t) => t !== value),
            }
        default:
            return {}
    }
}

export function applyEditPanelAction<T>(gif: EditPanelGif, action: EditPanelExternalAction, value: T): EditPanelGif {
    const changes = getEditPanelActionChanges<T>(gif, action, value)

    return { ...gif, ...changes }
}

export async function performEditPanelAction<T>(
    gifs: EditPanelGif[],
    action: EditPanelExternalAction,
    value: T,
    userToken: string = ''
) {
    const gifIds = gifs.map((g) => `${g.id}`)

    switch (action) {
        case 'add-tags':
            return addTags(gifIds, value as Tag[])
        case 'delete':
            return updateGifs(gifIds, 'delete_gif', true)
        case 'explicit':
            return updateGifs(gifIds, 'mark_explicit', true)
        case 'feature-tag':
            return updateGifs(gifIds, 'feature_tag', value)
        case 'queue':
            return queueGifsToTrend(gifIds)
        case 'remove-all-tags':
            return updateGifs(gifIds, 'delete_all_tags', true)
        case 'remove-tag':
            return removeTag(gifIds, value as Tag)
        case 'repair':
            return updateGifs(gifIds, 'repair_media', true)
        case 'schedule':
            return scheduleGifsToTrend(
                gifs.filter((g) => gifIds.indexOf(`${g.id}`) > -1),
                value as Date,
                userToken
            )
        case 'trend':
            return trendGifs(gifIds)
        case 'unfeature-tag':
            return updateGifs(gifIds, 'unfeature_tag', value)
        default:
            return Promise.resolve()
    }
}
