import { useContext, useMemo } from 'react'
import IconComponent from 'ui/src/components/icon'
import { heightFromControlSize } from 'utils/src/control-size'
import { contentTypeFromGif, iconFromContentType } from '../../../utilities/content-type'
import { METADATA_INFO_SIZE } from '../../constants'
import { EditPanelContext } from '../../contexts/panel'
import Field from '../../field'
import { titleFromField } from '../../utilities/field'
import { hasFieldPermission } from '../../utilities/permissions'
import { Container, Item, Label } from './style'

const ICON_SIZE = heightFromControlSize(METADATA_INFO_SIZE)

type Props = {
    className?: string
    gifId: string
}

const EditPanelMetadataContent = ({ className, gifId }: Props) => {
    const { colors, gifs, permissions } = useContext(EditPanelContext)

    const contentType = useMemo(() => {
        const gif = gifs.find((g) => g.id === gifId)
        return gif ? contentTypeFromGif(gif) : undefined
    }, [gifId, gifs])

    return (
        <Container className={className}>
            {!!contentType && (
                <Item>
                    <Label $color={colors.label}>Content Type</Label>
                    <IconComponent
                        color={colors.text}
                        height={ICON_SIZE}
                        icon={iconFromContentType(contentType)}
                        width={ICON_SIZE}
                    />
                </Item>
            )}
            {hasFieldPermission('indexed', permissions) && (
                <Item>
                    <Label $color={colors.label}>{titleFromField('indexed')}</Label>
                    <Field field="indexed" gifId={gifId} size={METADATA_INFO_SIZE} />
                </Item>
            )}
        </Container>
    )
}

export default EditPanelMetadataContent
