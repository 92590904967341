import styled, { css } from 'styled-components'

export const Image = styled.img<{ $sticker: boolean }>`
    border-radius: inherit;
    display: block;

    ${({ $sticker }) =>
        $sticker &&
        css`
            background-color: #000;
            background-image: linear-gradient(45deg, #0e0e0e 25%, transparent 25%, transparent 75%, #0e0e0e 75%),
                linear-gradient(45deg, #0e0e0e 25%, transparent 25%, transparent 75%, #0e0e0e 75%);
            background-size: 24px 24px;
            background-position:
                0 0,
                12px 12px;
        `}
`
