import panelColors from '../colors/panel'
import sidebarColors from '../colors/sidebar'
import { EditPanelColorScheme, EditPanelColors } from '../types/colors'

export function colorsFromColorScheme(scheme: EditPanelColorScheme): EditPanelColors {
    switch (scheme) {
        case 'panel':
            return panelColors
        case 'sidebar':
            return sidebarColors
    }
}
