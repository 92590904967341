import { giphyDarkestGrey } from '@giphy/colors'
import EditPanel from 'edit'
import { EditPanelGif } from 'edit/panel/types/gif'
import { useContext, useEffect } from 'react'
import styled, { keyframes } from 'styled-components'
import { GifPermissions } from 'types'
import { menuZIndex } from 'ui/src/constants'
import UserContext from '../../context/user-context'

const fadeIn = keyframes`
    0% { opacity: 0; }
    100% { opacity: 1; }
`

const Container = styled.div`
    align-items: center;
    animation: ${fadeIn} 0.1s ease-out;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: ${menuZIndex + 1};
`

const Dim = styled.div`
    background: rgba(0, 0, 0, 0.8);
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
`

const Wrapper = styled.div`
    height: 90%;
    max-height: 800px;
    min-height: 0;
    position: relative;
    width: 840px;
`

const Panel = styled(EditPanel)`
    border: 1px solid ${giphyDarkestGrey};
    border-radius: 6px;
    height: 100%;
    overflow: auto;
    width: 100%;
`

type Props = {
    gifs: EditPanelGif[]
    loading?: boolean
    permissions: GifPermissions
    onClose?: () => void
}

const EditPanelOverlay = ({ gifs, loading = false, permissions, onClose }: Props) => {
    const { user } = useContext(UserContext)
    const showPanel = loading || gifs.length > 0

    useEffect(() => {
        if (showPanel) {
            document.documentElement.style.overflow = 'hidden'
        } else {
            document.documentElement.style.overflow = ''
        }
    }, [showPanel])

    if (!showPanel) {
        return null
    }

    return (
        <Container>
            <Dim onClick={() => onClose?.()} />
            <Wrapper>
                <Panel
                    closeable
                    colorScheme="panel"
                    gifs={gifs}
                    loading={loading}
                    permissions={{
                        altText: false,
                        cta: !!user?.is_verified,
                        delete: permissions.delete,
                        featureTag: permissions.feature_tags,
                        languageTags: !!user?.permissions.edit_language_tags,
                        metadata: permissions.extended_metadata,
                        privacy: permissions.hide,
                        rate: permissions.rate,
                        staff: !!user?.is_staff,
                        tag: permissions.tag,
                        tagCounts: permissions.tag_counts,
                        takedown: !!user?.permissions.can_takedown_content,
                    }}
                    onClose={onClose}
                />
            </Wrapper>
        </Container>
    )
}

export default EditPanelOverlay
