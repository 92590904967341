import { useMemo } from 'react'
import { CalendarVisibleMonth } from '../types'

const DATES_ROWS = 6
const DATES_COUNT = DATES_ROWS * 7 // 7 days x 6 rows

export function useVisibleMonth(date: Date): CalendarVisibleMonth {
    return useMemo(() => {
        const first = new Date(date.getFullYear(), date.getMonth(), 1)
        const current = new Date(first)
        const offset = first.getDay()
        const dates: Date[] = []
        const leading: Date[] = []
        const trailing: Date[] = []

        current.setDate(current.getDate() - offset)

        for (let i = 0; i < DATES_COUNT; i++) {
            if (current < first) {
                leading.push(new Date(current))
            } else if (current.getMonth() !== date.getMonth()) {
                trailing.push(new Date(current))
            } else {
                dates.push(new Date(current))
            }

            current.setDate(current.getDate() + 1)
        }

        return {
            dates,
            leading,
            trailing,
        }
    }, [date])
}
