import { giphyYellow } from '@giphy/colors'
import styled from 'styled-components'

export const Content = styled.div`
    padding-left: 1em;
`

export const Dimmed = styled.span`
    opacity: 0.5;
`

export const Link = styled.a`
    color: inherit;
    text-decoration: underline;

    &:focus-visible {
        border-radius: 2px;
        outline: 2px solid ${giphyYellow};
    }
`
