import { giphyBlack, giphyBlue, giphyCharcoal, giphyDarkestGrey } from '@giphy/colors'
import { EditPanelColors } from '../types/colors'
import { DEFAULT_COLORS } from './defaults'

export default {
    ...DEFAULT_COLORS,
    backgroundColor: giphyBlack,
    controlDark: giphyDarkestGrey,
    headerHighlight: giphyBlue,
    headerInactive: giphyCharcoal,
} as EditPanelColors
