import { useContext, useState } from 'react'
import styled from 'styled-components'
import Collapsible from '../../../controls/collapsible'
import { CONTROL_SPACING } from '../../constants'
import { EditPanelContext } from '../../contexts/panel'
import FieldComponent from '../../field'
import { EditPanelField } from '../../types/field'
import { displayInlineFromField, iconFromField, titleFromField } from '../../utilities/field'
import TitleComponent from './title-component'

const Field = styled(FieldComponent)`
    div:has(+ &) {
        margin-right: 0;
    }
`

const Inputs = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${CONTROL_SPACING}px;
`

type Props = {
    className?: string
    defaultOpen?: boolean
    field: EditPanelField
}

export default ({ className, defaultOpen = false, field }: Props) => {
    const { colors, isCompact } = useContext(EditPanelContext)
    const [isOpen, setIsOpen] = useState<boolean>(defaultOpen)
    const icon = iconFromField(field)
    const title = titleFromField(field)
    const isInline = displayInlineFromField(field, isCompact)

    return (
        <Collapsible
            arrowColor={colors.labelArrow || colors.labelIcon}
            className={className}
            color={colors.label}
            defaultOpen={defaultOpen}
            disabled={isInline}
            icon={icon}
            iconColor={colors.labelIcon}
            title={title}
            titleComponent={
                <>
                    {isInline && <Field field={field} />}
                    <TitleComponent field={field} isFieldOpen={isOpen} />
                </>
            }
            onToggle={setIsOpen}
        >
            {!isInline && (
                <Inputs>
                    <Field field={field} />
                </Inputs>
            )}
        </Collapsible>
    )
}
