import { ReactNode, useContext, useEffect, useState } from 'react'
import { Cta } from '../../types'
import { EditPanelApiContext } from '../contexts/api'
import { EditPanelFieldContext } from '../contexts/field'
import { EditPanelMessageContext } from '../contexts/message'
import { EditPanelContext } from '../contexts/panel'
import { EditPanelField } from '../types/field'
import { titleFromField, valueKeyFromField } from '../utilities/field'
import { sharedValueFromGifs } from '../utilities/gifs'
import { hasFieldPermission } from '../utilities/permissions'

const EditPanelFieldContextManager = ({ children }: { children: ReactNode }) => {
    const { onChange } = useContext(EditPanelApiContext)
    const { gifs, gifIds, permissions } = useContext(EditPanelContext)
    const { showFieldMessage, showErrorMessage } = useContext(EditPanelMessageContext)
    const ctaKey = valueKeyFromField('cta')
    const [isCtaOn, setIsCtaOn] = useState<boolean>(!!sharedValueFromGifs(gifs, ctaKey))

    async function changeField<T>(field: EditPanelField, value: T) {
        // check for permission
        if (!hasFieldPermission(field, permissions)) {
            showErrorMessage(`You don't have permission to change this field.`, 'no-permission')
            return
        }

        try {
            await onChange(gifIds, field, value)
            showFieldMessage(field, value)
        } catch (err) {
            showErrorMessage(`An error occurred while changing ${titleFromField(field).toLowerCase()}`, field)
        }
    }

    // remove cta if toggled off
    useEffect(() => {
        const ctaKey = valueKeyFromField('cta')
        const cta = sharedValueFromGifs<string>(gifs, ctaKey)

        if (!isCtaOn && !!cta) {
            changeField<Cta>('cta', { link: '', text: '' })
        }
    }, [isCtaOn])

    return (
        <EditPanelFieldContext.Provider
            value={{
                isCtaOn,
                changeField,
                setIsCtaOn,
            }}
        >
            {children}
        </EditPanelFieldContext.Provider>
    )
}

export default EditPanelFieldContextManager
