import { useContext } from 'react'
import { EditPanelContext } from '../../contexts/panel'
import GifId from './fields/gif-id'
import Timestamp from './fields/timestamp'
import { Grid } from './style'

type Props = {
    className?: string
    metadata: any
}

const EditPanelMetadataTimestamps = ({ className, metadata }: Props) => {
    const { isCompact } = useContext(EditPanelContext)

    return (
        <Grid $compact={isCompact} className={className}>
            <GifId label="GIF ID" value={metadata.id} />
            <Timestamp label="Created" value={metadata.create_datetime} />
            <Timestamp label="Trended" value={metadata.trending_datetime} />
            <Timestamp label="Updated" value={metadata.update_datetime} />
        </Grid>
    )
}

export default EditPanelMetadataTimestamps
