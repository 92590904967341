import { capitalize } from 'utils/src/text/string'
import { Cta, Rating } from '../../../../types'
import { labelFromRating } from '../../../../utilities/rating'
import { EditPanelField } from '../../../types/field'
import { titleFromField } from '../../../utilities/field'

export const CtaMessage = ({ cta }: { cta?: Cta }) => <span>Source button {cta?.text ? 'updated' : 'removed'}</span>

export const DefaultMessage = ({ field, value }: { field: EditPanelField; value: any }) => (
    <span>
        {capitalize(titleFromField(field))} {value ? 'updated' : 'removed'}
    </span>
)

export const IndexedMessage = ({ isIndexed }: { isIndexed: boolean }) => (
    <span>{isIndexed ? `Added to` : `Removed from`} search index</span>
)

export const NoTrendMessage = ({ noTrend }: { noTrend: boolean }) => (
    <span>{noTrend ? `Removed from trending eligibility` : `Made eligible for trending`}</span>
)

export const PrivacyMessage = ({ isPrivate }: { isPrivate: boolean }) => (
    <span>
        Updated to <strong>{isPrivate ? 'private' : 'public'}</strong>
    </span>
)

export const RatingMessage = ({ rating }: { rating?: Rating }) =>
    rating ? (
        <span>
            Rating changed to <strong>{labelFromRating(rating)}</strong>
        </span>
    ) : (
        <span>Rating removed</span>
    )

export const UsernameMessage = ({ username }: { username?: string }) =>
    username ? (
        <span>
            Username changed to <strong>@{username}</strong>
        </span>
    ) : (
        <span>Username removed</span>
    )
