import Item from '../item'
import { Dimmed, Link } from '../style'

export function componentFromObject(object: any) {
    if (!object) {
        return null
    }

    return (
        <>
            {Object.keys(object)
                .sort()
                .map((key, i) => {
                    const itemId = Math.random().toString(36).substring(2, 11)
                    return (
                        <Item key={`${itemId}-${key}-${i}`} label={key} value={object[key]}>
                            {typeof object[key] === 'object' ? componentFromObject(object[key]) : null}
                        </Item>
                    )
                })}
        </>
    )
}

export function componentFromValue(value: any) {
    if (value === null) {
        return <Dimmed>null</Dimmed>
    }

    if (value === undefined) {
        return <Dimmed>undefined</Dimmed>
    }

    if (typeof value === 'boolean') {
        return value ? 'true' : <Dimmed>false</Dimmed>
    }

    if (Array.isArray(value)) {
        return <Dimmed>[]</Dimmed>
    }

    if (typeof value === 'object') {
        return <Dimmed>{}</Dimmed>
    }

    if (typeof value === 'string') {
        try {
            new URL(value)
            return (
                <>
                    <Dimmed>{'"'}</Dimmed>
                    <Link href={value} target="_blank" title={value}>
                        {value}
                    </Link>
                    <Dimmed>{'"'}</Dimmed>
                </>
            )
        } catch (err) {
            return (
                <>
                    <Dimmed>{`"`}</Dimmed>
                    {value}
                    <Dimmed>{`"`}</Dimmed>
                </>
            )
        }
    }

    return value
}
