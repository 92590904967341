import { ReactNode, useContext } from 'react'
import { ControlSize } from 'types'
import Button, { ButtonProps } from 'ui/src/components/controls/button'
import IconButton from 'ui/src/components/controls/icon-button'
import IconComponent from 'ui/src/components/icon'
import TooltipButton from '../components/button/tooltip'
import { EditPanelActionContext } from '../contexts/action'
import { EditPanelContext } from '../contexts/panel'
import { EditPanelAction } from '../types/action'
import { gradientFromAction, iconFromAction, titleFromAction } from '../utilities/action'

type Props = {
    action: EditPanelAction
    children?: ReactNode
    className?: string
    iconOnly?: boolean
    size: ControlSize
}

const EditPanelFooterButton = ({ action, children, className, iconOnly = false, size }: Props) => {
    const { colors } = useContext(EditPanelContext)
    const { performAction } = useContext(EditPanelActionContext)
    const gradient = gradientFromAction(action)
    const icon = iconFromAction(action)
    const label = titleFromAction(action)

    const buttonProps: Partial<ButtonProps> = {
        className,
        color: 'transparent',
        size,
        title: label,
        onClick: () => onClick?.(),
    }

    function onClick() {
        performAction(action, undefined)
    }

    if (children) {
        return (
            <TooltipButton
                icon={icon}
                iconColor={colors.footerIcon}
                iconGradient={gradient}
                iconOnly={iconOnly}
                label={label}
                size={size}
                textColor={colors.footer}
            >
                {children}
            </TooltipButton>
        )
    }

    if (iconOnly) {
        return (
            <IconButton
                {...buttonProps}
                color={colors.footerIcon}
                gradient={gradient}
                icon={icon}
                inverted
                title={label}
            />
        )
    }

    return (
        <Button {...buttonProps} textColor={colors.footer}>
            <IconComponent color={colors.footerIcon} gradient={gradient} height={20} icon={icon} width={20} />
            {label}
        </Button>
    )
}

export default EditPanelFooterButton
