import { CSSProperties, ReactNode, useContext, useRef } from 'react'
import { ControlSize } from 'types/src/controls'
import { Gradient } from 'types/src/gradient'
import { ButtonProps } from 'ui/src/components/controls/button'
import IconButton from 'ui/src/components/controls/icon-button'
import { PortalTooltip, useTooltip } from 'ui/src/components/controls/tooltip'
import IconComponent from 'ui/src/components/icon'
import Icon from 'ui/src/types/icons'
import { generateId } from 'utils/src/text/string'
import { TOOLTIP_WIDTH } from '../../../constants'
import { EditPanelContext } from '../../../contexts/panel'
import { Button } from './style'

type Props = {
    children?: ReactNode
    className?: string
    color?: string
    icon?: Icon
    iconColor?: string
    iconGradient?: Gradient
    iconOnly?: boolean
    iconScale?: number
    label: ReactNode
    size: ControlSize
    style?: CSSProperties
    textColor?: string
    title?: string
    tooltipWidth?: number
}

const EditPanelTooltipButton = ({
    children,
    className,
    color = 'transparent',
    icon,
    iconColor,
    iconGradient,
    iconOnly = false,
    iconScale,
    label,
    size,
    style,
    textColor,
    title,
    tooltipWidth = TOOLTIP_WIDTH,
}: Props) => {
    const tooltipIdRef = useRef<string>(generateId('edit-panel-tooltip-button-'))
    const { colors } = useContext(EditPanelContext)

    const { isOpen, listeners } = useTooltip({
        color: colors.tooltip,
        direction: 'up',
        id: tooltipIdRef.current,
        triggerType: 'click',
        width: tooltipWidth,
    })

    const buttonProps: Partial<ButtonProps> = {
        ...listeners,
        className,
        color,
        size,
        style,
    }

    return (
        <>
            {icon && iconOnly ? (
                <IconButton
                    {...buttonProps}
                    color={iconColor}
                    gradient={iconGradient}
                    icon={icon}
                    iconScale={iconScale}
                    inverted
                    title={title || (typeof label === 'string' ? label : undefined)}
                />
            ) : (
                <Button {...buttonProps} className={className} textColor={textColor || colors.text} title={title}>
                    {icon && (
                        <IconComponent color={iconColor} gradient={iconGradient} height={20} icon={icon} width={20} />
                    )}
                    {label}
                </Button>
            )}
            <PortalTooltip isOpen={isOpen}>{children}</PortalTooltip>
        </>
    )
}

export default EditPanelTooltipButton
