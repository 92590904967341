import { CalendarDay } from '../types'

export function labelFromDay(day: CalendarDay): string {
    switch (day) {
        case 'friday':
            return 'F'
        case 'monday':
            return 'M'
        case 'saturday':
            return 'S'
        case 'sunday':
            return 'S'
        case 'thursday':
            return 'T'
        case 'tuesday':
            return 'T'
        case 'wednesday':
            return 'W'
    }
}
