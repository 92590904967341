import { giphyUrl } from '@/app/util/env'
import { getCookie } from '@/util/url'

export function POST(data: { [key: string]: any }) {
    return {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/x-www-form-urlencoded',
            'X-CSRFToken': getCookie('csrftoken'),
            'X-Requested-With': 'XMLHttpRequest',
            credentials: 'same-origin',
        },
        body: new URLSearchParams(data).toString(),
    } as RequestInit
}

export const updateGif = async (gifId: string, prop: string, value?: any) => {
    const f = await fetch(
        `${giphyUrl}/ajax/gif/update`,
        POST({
            gif_id: gifId,
            key: prop,
            value,
        })
    )
    const results = await f.json()

    if (results.code < 200 || results.code >= 300) {
        throw new Error(results.message)
    }

    return results?.data
}

export const updateGifs = async (gifIds: string[], prop: string, value?: any) => {
    return Promise.all(gifIds.map((id) => updateGif(id, prop, value)))
}
