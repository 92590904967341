import { giphyDarkGrey, giphyIndigo } from '@giphy/colors'
import { CSSProperties, SyntheticEvent, useEffect, useMemo, useRef, useState } from 'react'
import { ControlSize } from 'types/src/controls'
import { Gradient } from 'types/src/gradient'
import { FEATURED_TAG_GRADIENT, REMOVE_TAG_GRADIENT } from '../../../constants'
import { Clickable, Count, Hash, Pill } from './style'

type Props = {
    className?: string
    color?: string
    count?: number
    featureable?: boolean
    featured?: boolean
    highlightColor?: string
    highlighted?: boolean
    removeable?: boolean
    size: ControlSize
    style?: CSSProperties
    text: string
    onFeature?: (featured: boolean) => void
    onRemove?: () => void
}

const TagPill = ({
    className,
    color = giphyDarkGrey,
    count,
    featured = false,
    featureable = false,
    highlightColor = giphyIndigo,
    highlighted = false,
    removeable = false,
    size,
    style,
    text,
    onFeature,
    onRemove,
}: Props) => {
    const clickableRef = useRef<HTMLSpanElement>(null)
    const defaultGradient = featured ? FEATURED_TAG_GRADIENT : undefined
    const [gradient, setGradient] = useState<Gradient | undefined>(defaultGradient)

    const countString = useMemo(() => {
        if (typeof count !== 'number') {
            return ''
        }

        return new Intl.NumberFormat('en-US').format(count)
    }, [count])

    function onKeyDown(e: any) {
        if (e.key === 'Enter') {
            onFeature?.(!featured)
        }
    }

    function onMouseOut() {
        setGradient(defaultGradient)
    }

    function onMouseOver(e: SyntheticEvent<HTMLElement, MouseEvent>) {
        if (e.target === clickableRef.current) {
            !featured && setGradient(FEATURED_TAG_GRADIENT)
        } else {
            setGradient(REMOVE_TAG_GRADIENT)
        }
    }

    useEffect(() => {
        setGradient(defaultGradient)
    }, [featured])

    return (
        <Pill
            $clickable={featureable}
            $highlighted={!gradient && highlighted}
            $highlightColor={highlightColor}
            className={className}
            color={color}
            gradient={gradient}
            removeable={removeable}
            removeOptions={{ title: 'Remove Tag' }}
            round
            size={size}
            style={style}
            onMouseOut={featureable ? onMouseOut : undefined}
            onMouseOver={featureable ? onMouseOver : undefined}
            onRemove={onRemove}
        >
            {featureable ? (
                <Clickable
                    $removeable={removeable}
                    $size={size}
                    ref={clickableRef}
                    tabIndex={1}
                    title={`${featured ? 'Unfeature' : 'Feature'} Tag`}
                    onClick={() => onFeature?.(!featured)}
                    onKeyDown={onKeyDown}
                >
                    <Hash>#</Hash>
                    {text}
                    {countString ? <Count>({countString})</Count> : null}
                </Clickable>
            ) : (
                <>
                    <Hash>#</Hash>
                    {text}
                    {countString ? <Count>({countString})</Count> : null}
                </>
            )}
        </Pill>
    )
}

export default TagPill
