import { fetchChannelByUsername } from '@/api/channel'
import {
    GifDetails,
    fetchExtendedMetadata,
    fetchGifDetails,
    fetchLanguageTags,
    fetchMetadata,
    fetchTagCounts,
    fetchViewCount,
} from '@/api/gif'
import { Modal } from '@/app/components/modal'
import Panel from '@/components/edit/panel-overlay'
import { applyEditPanelAction, performEditPanelAction } from '@/util/edit/action'
import { applyEditPanelChange, performEditPanelChange } from '@/util/edit/change'
import { IUser } from '@giphy/js-types'
import { EditPanelApiContext } from 'edit'
import { EditPanelExternalAction } from 'edit/panel/types/action'
import { EditPanelField } from 'edit/panel/types/field'
import { EditPanelGif } from 'edit/panel/types/gif'
import { ReactNode, createContext, useContext, useState } from 'react'
import { PortalMessageContext } from 'ui/src/components/message/portal-message/context'
import { useGifPermissions } from '../util/use-gif-permissions'
import UserContext from './user-context'
import ContentTakedown from '@/components/edit/content-takedown'

export type EditContextProps = {
    gifs: EditPanelGif[]
    addToEdit: (gifId: number | string) => void
    closeEdit: () => void
    removeFromEdit: (gifId: number | string) => void
}

export const EditContext = createContext({} as EditContextProps)

const EditManager = ({ children }: { children: ReactNode }) => {
    const { user } = useContext(UserContext)
    const { showMessage } = useContext(PortalMessageContext)
    const [gifDetails, setGifDetails] = useState<GifDetails[]>([])
    const [loading, setLoading] = useState<boolean>(false)
    const [contentTakedown, showContentTakedown] = useState(false)
    const permissions = useGifPermissions(gifDetails)
    const gifs = gifDetails.map((d) => d.gif)

    async function addToEdit(gifId: number | string) {
        if (gifDetails.findIndex((d) => `${d.gif.id}` === `${gifId}`) > -1) {
            return
        }

        try {
            setLoading(true)
            const details = await fetchGifDetails(`${gifId}`)
            setGifDetails((state) => [...state, details])
        } catch (err) {
            showMessage(`An error occurred while loading details.`, {
                style: 'error',
            })
        } finally {
            setLoading(false)
        }
    }

    function closeEdit() {
        setGifDetails([])
    }

    function removeFromEdit(gifId: number | string) {
        setGifDetails((state) => state.filter((g) => g.gif.id !== gifId))
    }

    async function fetchUserByUsername(username: string): Promise<IUser> {
        return await fetchChannelByUsername(username).then(({ user }) => user)
    }

    async function onAction<T>(gifIds: string[], action: EditPanelExternalAction, value: T) {
        await performEditPanelAction<T>(
            gifs.filter((g) => gifIds.includes(`${g.id}`)),
            action,
            value,
            user?.token
        )

        if (action === 'takedown') {
            showContentTakedown(true)
        }

        // apply changes
        setGifDetails((state) => {
            return state.map((d) => {
                if (gifIds.includes(`${d.gif.id}`)) {
                    const gif = applyEditPanelAction<T>(d.gif, action, value)
                    return { ...d, gif }
                }
                return d
            })
        })
    }

    async function onChange<T>(gifIds: string[], field: EditPanelField, value: T) {
        let editGifs = gifs.filter((g) => gifIds.includes(`${g.id}`))

        // hit api
        await performEditPanelChange<T>(editGifs, field, value)

        // apply changes
        editGifs = await applyEditPanelChange<T>(editGifs, field, value)

        // update gifs
        setGifDetails((state) => {
            return state.map((d) => {
                const foundIndex = editGifs.findIndex((g) => g.id === d.gif.id)

                if (foundIndex > -1) {
                    return { ...d, gif: editGifs[foundIndex] }
                }

                return d
            })
        })
    }

    return (
        <EditContext.Provider value={{ gifs, addToEdit, closeEdit, removeFromEdit }}>
            <EditPanelApiContext.Provider
                value={{
                    fetchLanguageTags,
                    fetchMetadata,
                    fetchExtendedMetadata,
                    fetchTagCounts,
                    fetchUserByUsername,
                    fetchViewCount,
                    onAction,
                    onChange,
                }}
            >
                {children}
                <Panel gifs={gifs} loading={loading} permissions={permissions} onClose={closeEdit} />
                {contentTakedown && (
                    <Modal
                        onClose={() => {
                            showContentTakedown(false)
                        }}
                        title="Content Takedown"
                    >
                        <ContentTakedown
                            gifIds={gifs.map((g) => g.id)}
                            onClose={() => {
                                showContentTakedown(false)
                            }}
                        />
                    </Modal>
                )}
            </EditPanelApiContext.Provider>
        </EditContext.Provider>
    )
}

export default EditManager
