import styled, { css } from 'styled-components'
import { colorToRgba } from 'utils/src/color'
import { borderRadiusFromControlSize } from 'utils/src/control-size'
import { FOOTER_SIZE, GRID_SPACING } from '../constants'
import Field from '../field'
import ButtonComponent from './button'

export const Button = styled(ButtonComponent)`
    flex-shrink: 0;

    > span {
        padding-top: 2px;
    }

    i,
    svg {
        display: inline-block;
        margin-right: 0.6em;
        margin-top: -2px;
        vertical-align: middle;
    }
`

export const Divider = styled.div`
    flex-shrink: 0;
    height: 20px;
    width: 1px;
`

export const Footer = styled.div<{
    $backgroundColor: string
    $color?: string
    $compact: boolean
    $dividerColor: string
}>`
    align-items: center;
    bottom: 0;
    display: flex;
    gap: 4px;
    justify-content: space-between;
    position: sticky;
    width: 100%;

    ${Divider} {
        background: ${({ $dividerColor }) => $dividerColor};
    }

    ${({ $backgroundColor, $compact, $color }) =>
        $color &&
        $compact &&
        css`
            > button,
            > div {
                position: relative;
            }

            &:before {
                background: ${$color || $backgroundColor};
                border-radius: ${borderRadiusFromControlSize(FOOTER_SIZE)}px;
                content: '';
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
                width: 100%;
            }
        `}

    ${({ $backgroundColor, $color, $dividerColor, $compact }) =>
        $compact
            ? css`
                  background: ${$backgroundColor};
                  padding: 6px ${GRID_SPACING}px;
              `
            : css`
                  background: ${$color || $backgroundColor};
                  border-top: 1px solid ${colorToRgba($dividerColor, 0.25)};
                  padding: 16px 10px;
              `}
`

export const PrivacyToggle = styled(Field)`
    margin-right: auto;
    max-width: 220px;
`
