import { NestedKeyOf } from 'types'
import { ContentType } from '../../types'
import { contentTypeFromGif } from '../../utilities/content-type'
import { EditPanelGif } from '../types/gif'

function objectValueFromString<T>(object: Object, key: string): T {
    const keys = key.split('.')
    let value: any = { ...object }

    keys.forEach((key) => {
        if (key in (value || {})) {
            value = value[key]
        } else {
            return
        }
    })

    return value
}

export function filterGifsById(gifs: EditPanelGif[], id?: string): EditPanelGif[] {
    if (id) {
        const gif = gifs.find((g) => g.id === id)
        return gif ? [gif] : []
    }
    return gifs
}

export function sharedContentTypeFromGifs(gifs: EditPanelGif[]): ContentType {
    const firstContentType = contentTypeFromGif(gifs[0])

    if (gifs.every((gif) => contentTypeFromGif(gif) === firstContentType)) {
        return firstContentType
    }

    return 'gif'
}

export function sharedValueFromGifs<T>(
    gifs: EditPanelGif[],
    key: NestedKeyOf<EditPanelGif>,
    id?: string
): T | undefined {
    const targetGifs = filterGifsById(gifs, id)
    const firstValue = objectValueFromString<T>(targetGifs[0], key)

    if (
        targetGifs.every((gif) => {
            return objectValueFromString<T>(gif, key) === firstValue
        })
    ) {
        return firstValue
    }
}
