import styled from 'styled-components'
import { CONTROL_SPACING, GRID_SPACING } from '../../constants'

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${GRID_SPACING}px;
    width: 100%;
`

export const Inputs = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${CONTROL_SPACING}px;
`
