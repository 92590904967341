import { giphyYellow } from '@giphy/colors'
import { IGif } from '@giphy/js-types'
import { CSSProperties, ReactNode, useRef } from 'react'
import { ControlSize } from 'types/src/controls'
import { generateId } from 'utils/src/text/string'
import { useImageDimensions } from './hooks/use-image-dimensions'
import Preview from './preview'
import { Badge, Cascade, Media } from './style'

type Props = {
    backgroundColor?: string
    badgeColor?: string
    badgeSize?: ControlSize
    children?: ReactNode
    className?: string
    gifs: IGif[]
    height: number
    muteColor?: string
    style?: CSSProperties
    width: number
}

const MediaPreview = ({
    backgroundColor = '#000',
    badgeColor = giphyYellow,
    badgeSize = 'medium',
    children,
    className,
    gifs,
    height,
    muteColor = '#fff',
    style,
    width,
}: Props) => {
    const idRef = useRef<string>(generateId(`media-preview-`))
    const gif = gifs[0]
    const isBulk = gifs.length > 1
    const aspectRatio = gif.images.original.width / gif.images.original.height
    const dimensions = useImageDimensions(width, height, aspectRatio, isBulk)

    return (
        <Media $bulk={isBulk} className={className} style={style}>
            {isBulk && <Cascade />}
            <Preview
                backgroundColor={backgroundColor}
                gif={gif}
                height={dimensions.height}
                key={`${idRef.current}-${gif.id}`}
                muteColor={muteColor}
                muteSize={badgeSize}
                width={dimensions.width}
            >
                {isBulk && (
                    <Badge color={badgeColor} size={badgeSize}>
                        {gifs.length}
                    </Badge>
                )}
                {children}
            </Preview>
        </Media>
    )
}

export default MediaPreview
