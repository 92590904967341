import styled, { css, keyframes } from 'styled-components'
import { heightFromControlSize } from 'utils/src/control-size'
import ToggleComponent from './toggle'

const fadeIn = keyframes`
    0% { opacity: 0; }
    100% { opacity: 1; }
`

export const Container = styled.div`
    display: grid;
    gap: 6px;
    grid-template-columns: 1fr;
`

export const Content = styled.div<{ $ignoreAnimation: boolean }>`
    min-width: 0;

    ${({ $ignoreAnimation }) =>
        !$ignoreAnimation &&
        css`
            animation: ${fadeIn} 0.15s ease-out;
        `}
`

export const Title = styled.div<{ $color: string }>`
    align-items: center;
    color: ${({ $color }) => $color};
    display: flex;
    font-size: 13px;
    gap: 6px;
    min-height: ${heightFromControlSize('mini')}px;
`

export const Toggle = styled(ToggleComponent)`
    margin-right: auto;
`
