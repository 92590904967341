import { useContext } from 'react'
import { Gradient } from 'types/src/gradient'
import PortalAlert from 'ui/src/components/alert/portal-alert'
import Button from 'ui/src/components/controls/button'
import { ALERT_CANCEL_LABEL, ALERT_CONTINUE_LABEL } from '../constants'
import { EditPanelContext } from '../contexts/panel'

type Props = {
    className?: string
    gradient?: Gradient
    isOpen?: boolean
    label?: string
    onCancel: () => void
    onContinue: () => void
}

const EditPanelAlert = ({
    className,
    gradient,
    isOpen = false,
    label = ALERT_CONTINUE_LABEL,
    onCancel,
    onContinue,
}: Props) => {
    const { colors } = useContext(EditPanelContext)

    return (
        <PortalAlert blurred className={className} isOpen={isOpen}>
            <Button color={colors.button} size="medium" textColor={colors.buttonText} onClick={onCancel}>
                {ALERT_CANCEL_LABEL}
            </Button>
            <Button gradient={gradient || colors.gradient} size="medium" onClick={() => onContinue()}>
                {label}
            </Button>
        </PortalAlert>
    )
}

export default EditPanelAlert
