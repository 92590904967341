import { giphyYellow } from '@giphy/colors'
import styled, { css } from 'styled-components'
import IconComponent from 'ui/src/components/icon'

export const Arrow = styled(IconComponent)<{ $open: boolean }>`
    margin-right: 0.25em;
    opacity: ${({ $open }) => ($open ? 0.5 : 0.25)};
    transition: all 0.1s ease-out;

    ${({ $open }) =>
        !$open &&
        css`
            transform: rotate(-90deg);
        `}
`

export const Button = styled.span`
    align-items: center;
    cursor: pointer;
    display: inline-flex;

    &:focus-visible {
        border-radius: 2px;
        outline: 2px solid ${giphyYellow};
    }

    &:active {
        opacity: 0.8;
    }
`

export const Content = styled.div`
    padding-left: 20px;
`

export const Label = styled.div`
    display: flex;
    width: 100%;

    ${Button} {
        flex-shrink: 0;
    }
`

export const Preview = styled.div`
    box-sizing: border-box;
    opacity: 0.25;
    overflow: hidden;
    padding-left: 0.25em;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
`
