import { Gradient } from 'types/src/gradient'
import { pluralize } from 'utils/src/text/string'
import { EditPanelAction } from '../types/action'

export function actionRequiresConfirmation(action: EditPanelAction): boolean {
    switch (action) {
        case 'delete':
        case 'explicit':
        case 'queue':
        case 'remove-all-tags':
        case 'schedule':
        case 'trend':
            return true
        default:
            return false
    }
}

export function actionRequiresModal(action: EditPanelAction): boolean {
    switch (action) {
        case 'takedown':
            return true
        default:
            return false
    }
}
export function alertDescriptionFromAction<T>(action: EditPanelAction, value: T, gifCount: number): string | undefined {
    const gifsLabel = gifCount === 1 ? `This GIF` : `${gifCount} GIFs`

    switch (action) {
        case 'delete':
            return `${gifsLabel} will be deleted and no longer accessible for any users.`
        case 'explicit':
            return `${gifsLabel} will be marked as explicit.`
        case 'queue':
            return `${gifsLabel} will be added to the trending feed queue.`
        case 'remove-all-tags':
            return `${gifsLabel} will have all tags removed.`
        case 'schedule':
            return `${gifsLabel} will be scheduled to trend on ${(value as Date).toLocaleDateString('en-US', {
                day: 'numeric',
                month: 'long',
                year: 'numeric',
            })} at ${(value as Date).toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit' })}.`
        case 'trend':
            return `${gifsLabel} will be added to the trending feed.`
        default:
            return
    }
}

export function alertGradientFromAction(action: EditPanelAction): Gradient | undefined {
    switch (action) {
        case 'delete':
        case 'remove-all-tags':
        case 'remove-tag':
            return 'red-pink'
        default:
            return
    }
}

export function alertTitleFromAction(action: EditPanelAction, gifCount: number): string {
    const gifsLabel = pluralize('GIF', gifCount)

    switch (action) {
        case 'delete':
            return `Deleting ${gifsLabel}`
        case 'explicit':
            return `Marking as Explicit`
        case 'queue':
            return `Queuing ${gifsLabel}`
        case 'remove-all-tags':
            return `Removing Tags`
        case 'schedule':
            return `Scheduling ${gifsLabel}`
        case 'trend':
            return `Trending ${gifsLabel}`
        default:
            return ''
    }
}
