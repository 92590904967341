import { useContext, useMemo, useState } from 'react'
import useAsyncEffect from 'utils/src/hooks/use-async-effect'
import { CONTROL_SIZE_SLIM, PREVIEW_WIDTH } from '../../constants'
import { EditPanelApiContext } from '../../contexts/api'
import { EditPanelContext } from '../../contexts/panel'
import Field from '../../field'
import { hasFieldPermission } from '../../utilities/permissions'
import { getPreviewHeight, getViewString } from '../../utilities/preview'
import { Footer, Preview, Views, ViewsIcon } from './style'

type Props = {
    className?: string
}

const EditPanelInfoPreview = ({ className }: Props) => {
    const { fetchViewCount } = useContext(EditPanelApiContext)
    const { colors, gifs, isCompact, permissions, width: panelWidth } = useContext(EditPanelContext)
    const [viewCount, setViewCount] = useState<number | undefined>()
    const previewWidth = isCompact ? panelWidth : PREVIEW_WIDTH

    const longViews = useMemo(() => {
        return `${getViewString(viewCount || 0)} Views`
    }, [viewCount, isCompact])

    const shortViews = useMemo(() => {
        return `${getViewString(viewCount || 0, true)}${isCompact ? '' : ' Views'}`
    }, [viewCount, isCompact])

    useAsyncEffect(async () => {
        try {
            const count = await fetchViewCount(`${gifs[0].id}`)
            setViewCount(count)
        } catch (err) {}
    }, [])

    return (
        <div className={className}>
            <Preview
                backgroundColor={colors.controlDark}
                badgeColor={colors.badgeColor}
                gifs={gifs}
                height={getPreviewHeight(gifs[0], previewWidth)}
                width={previewWidth}
            />
            <Footer>
                {isCompact && hasFieldPermission('privacy', permissions) && (
                    <Field field="privacy" size={CONTROL_SIZE_SLIM} />
                )}
                {typeof viewCount === 'number' && (
                    <Views title={longViews}>
                        <ViewsIcon color={colors.labelIcon} icon="view" height={20} width={20} />
                        {shortViews}
                    </Views>
                )}
            </Footer>
        </div>
    )
}

export default EditPanelInfoPreview
