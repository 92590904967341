import { CSSProperties, ReactNode, useRef, useState } from 'react'
import PortalAlertContextManager from 'ui/src/components/alert/portal-alert/context'
import PortalTooltipContextManager from 'ui/src/components/controls/tooltip/portal-tooltip/context'
import { useNodeWidth } from 'utils/src/hooks'
import { EditPanelContext } from './contexts/panel'
import Fields from './info'
import EditPanelActionContextManager from './managers/action'
import EditPanelFieldContextManager from './managers/field'
import EditPanelInternalActionContextManager from './managers/internal-action'
import EditPanelMessageContextManager from './managers/message'
import EditPanelPortalMessageContextManager from './managers/portal-message'
import Metadata from './metadata'
import { Container, Content, Footer, Header, Loading } from './style'
import { EditPanelColorScheme, EditPanelColors } from './types/colors'
import { EditPanelGif } from './types/gif'
import { EditPanelPermissions } from './types/permissions'
import { EditPanelTab, editPanelTabs } from './types/tab'
import { colorsFromColorScheme } from './utilities/colors'
import { hasActionPermission, hasFieldPermission } from './utilities/permissions'
import { actionsFromTab, fieldsFromTab } from './utilities/tab'

export { EditPanelApiContext } from './contexts/api'
export type { EditPanelApiContextProps } from './contexts/api'

type Props = {
    className?: string
    closeable?: boolean
    colors?: Partial<EditPanelColors>
    colorScheme?: EditPanelColorScheme
    collectionsComponent?: ReactNode
    defaultTab?: EditPanelTab
    gifs?: EditPanelGif[]
    loading?: boolean
    permissions: EditPanelPermissions
    style?: CSSProperties
    onClose?: () => void
}

const EditPanel = ({
    className,
    closeable = false,
    colors: colorsProp = {},
    colorScheme = 'panel',
    collectionsComponent,
    defaultTab = editPanelTabs[0],
    gifs = [],
    loading = false,
    permissions,
    style,
    onClose,
}: Props) => {
    const containerRef = useRef<HTMLDivElement>(null)
    const [tab, setTab] = useState<EditPanelTab>(defaultTab)
    const width = useNodeWidth(containerRef)
    const isCompact = width < 600

    const actions = actionsFromTab(tab).filter((a) => hasActionPermission(a, permissions))
    const fields = fieldsFromTab(tab).filter((f) => hasFieldPermission(f, permissions))
    const gifIds = gifs.map((gif) => `${gif.id}`)

    const colors = {
        ...colorsFromColorScheme(colorScheme),
        ...colorsProp,
    }

    function getContent() {
        switch (tab) {
            case 'collections':
                return collectionsComponent
            case 'info':
                return <Fields />
            case 'metadata':
                return <Metadata />
        }
    }

    return (
        <EditPanelContext.Provider
            value={{
                actions,
                colors,
                fields,
                gifIds,
                gifs,
                isCompact,
                permissions,
                tab,
                width,
                setTab,
            }}
        >
            <PortalAlertContextManager>
                <PortalTooltipContextManager>
                    <EditPanelPortalMessageContextManager>
                        <EditPanelMessageContextManager>
                            <EditPanelInternalActionContextManager>
                                <EditPanelActionContextManager>
                                    <EditPanelFieldContextManager>
                                        <Container
                                            $color={colors.backgroundColor}
                                            $compact={isCompact}
                                            $textColor={colors.text}
                                            className={className}
                                            style={style}
                                            ref={containerRef}
                                        >
                                            {gifs.length > 0 && (
                                                <>
                                                    <Header closeable={closeable} onClose={onClose} />
                                                    <Content>{getContent()}</Content>
                                                    <Footer />
                                                </>
                                            )}
                                        </Container>
                                        {loading && <Loading />}
                                    </EditPanelFieldContextManager>
                                </EditPanelActionContextManager>
                            </EditPanelInternalActionContextManager>
                        </EditPanelMessageContextManager>
                    </EditPanelPortalMessageContextManager>
                </PortalTooltipContextManager>
            </PortalAlertContextManager>
        </EditPanelContext.Provider>
    )
}

export default EditPanel
