import { useContext } from 'react'
import { ControlSize } from 'types'
import Button from 'ui/src/components/controls/button'
import TimeInput from 'ui/src/components/controls/time-input'
import { CalendarContext } from '../../context'
import { dateToTime, dateWithTime } from '../../utilities/date'
import { Container } from './style'

type Props = {
    className?: string
    size?: ControlSize
    toggleColor?: string
}

export default ({ className, size = 'small', toggleColor }: Props) => {
    const { colors, selected, time, setSelected, setTime, setVisible } = useContext(CalendarContext)

    function onChange(value: string) {
        setTime(value)

        if (selected) {
            setSelected(dateWithTime(selected, value))
        }
    }

    function onNowClick() {
        const now = new Date()
        setSelected(now)
        setTime(dateToTime(now))
        setVisible(now)
    }

    return (
        <Container className={className} style={{ color: colors.header }}>
            <TimeInput
                color={colors.button}
                size={size}
                toggleColor={toggleColor || colors.header}
                value={time}
                onChange={onChange}
            />
            <Button color={colors.button} size={size} onClick={onNowClick}>
                Now
            </Button>
        </Container>
    )
}
