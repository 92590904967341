import { EditPanelAction } from '../types/action'
import { EditPanelField } from '../types/field'
import { EditPanelPermissions } from '../types/permissions'
import { EditPanelTab } from '../types/tab'

export function hasActionPermission(action: EditPanelAction, permissions: EditPanelPermissions): boolean {
    if (permissions.staff) {
        return true
    }

    switch (action) {
        case 'add-tags':
        case 'copy-all-tags':
        case 'remove-all-tags':
        case 'remove-tag':
            return permissions.tag
        case 'copy-gif-id':
            return true
        case 'delete':
            return permissions.delete
        case 'feature-tag':
        case 'unfeature-tag':
            return permissions.featureTag
        case 'takedown':
            return permissions.takedown
        default:
            return permissions.staff
    }
}

export function hasFieldPermission(field: EditPanelField, permissions: EditPanelPermissions): boolean {
    if (permissions.staff) {
        return true
    }

    switch (field) {
        case 'description':
        case 'source':
        case 'title':
            return true
        case 'alt-text':
            return permissions.altText
        case 'cta':
            return permissions.cta
        case 'privacy':
            return permissions.privacy
        case 'rating':
            return permissions.rate
        default:
            return permissions.staff
    }
}

export function hasTabPermission(tab: EditPanelTab, permissions: EditPanelPermissions): boolean {
    if (permissions.staff) {
        return true
    }

    switch (tab) {
        case 'metadata':
            return permissions.metadata
        default:
            return true
    }
}
