import styled, { css } from 'styled-components'
import IconButton from 'ui/src/components/controls/icon-button'
import TabsComponent from 'ui/src/components/controls/tabs'
import { GRID_SPACING } from '../constants'

export const Close = styled(IconButton)<{ $borderColor: string; $hasBackground: boolean }>`
    flex-shrink: 0;

    ${({ $borderColor, $hasBackground }) =>
        $hasBackground
            ? css`
                  margin-left: ${GRID_SPACING}px;
              `
            : css`
                  border-bottom: 1px solid ${$borderColor};
                  border-radius: 0;
              `}
`

export const Header = styled.div<{ $color: string }>`
    background: ${({ $color }) => $color};
    display: flex;
    position: sticky;
    top: 0;
    width: 100%;
`

export const Tabs = styled(TabsComponent)`
    width: 100%;
`
