import { EditPanelAction } from '../../../types/action'
import {
    AddTagsMessage,
    DeleteMessage,
    FeatureTagMessage,
    RemoveTagMessage,
    ScheduleMessage,
    UnfeatureTagMessage,
} from './messages'

type Props = {
    action: EditPanelAction
    value: any
}

const EditPanelActionMessage = ({ action, value }: Props) => {
    switch (action) {
        case 'add-tags':
            return <AddTagsMessage tags={value || []} />
        case 'copy-all-tags':
            return 'Copied all tags to clipboard'
        case 'copy-gif-id':
            return 'Copied GIF ID to clipboard'
        case 'delete':
            return <DeleteMessage />
        case 'escalate':
            return 'Escalated'
        case 'explicit':
            return 'Marked as explicit'
        case 'feature-tag':
            return <FeatureTagMessage tag={value} />
        case 'queue':
            return 'Added to trending queue'
        case 'remove-all-tags':
            return 'All tags removed'
        case 'remove-tag':
            return <RemoveTagMessage tag={value} />
        case 'repair':
            return 'Media repair initiated'
        case 'reports':
            return 'Reports created'
        case 'schedule':
            return <ScheduleMessage date={value} />
        case 'takedown':
            return null
        case 'trend':
            return 'Added to trending feed'
        case 'unfeature-tag':
            return <UnfeatureTagMessage tag={value} />
    }
}

export default EditPanelActionMessage
