import { Gradient } from 'types/src/gradient'

export const calendarDays = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'] as const
export type CalendarDay = (typeof calendarDays)[number]

export type CalendarColors = {
    button: string
    default: string
    gradient?: Gradient
    header: string
    highlight: string
    label: string
}

export type CalendarDateStatus = 'default' | 'dimmed' | 'highlighted' | 'selected'

export type CalendarVisibleMonth = {
    dates: Date[]
    leading: Date[]
    trailing: Date[]
}
