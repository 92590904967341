import { useContext } from 'react'
import { CalendarContext } from '../context'
import Block from './block'

const CalendarDates = () => {
    const { id, visibleMonth } = useContext(CalendarContext)
    const { dates, leading, trailing } = visibleMonth

    return (
        <>
            {leading.map((d) => (
                <Block date={d} dimmed key={`${id}-date-${d.getTime()}`} />
            ))}
            {dates.map((d) => (
                <Block date={d} key={`${id}-date-${d.getTime()}`} />
            ))}
            {trailing.map((d) => (
                <Block date={d} dimmed key={`${id}-date-${d.getTime()}`} />
            ))}
        </>
    )
}

export default CalendarDates
