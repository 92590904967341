import { ReactNode } from 'react'
import IconType from 'ui/src/types/icons'
import { Arrow, Icon, Toggle } from './style'

type Props = {
    arrowColor: string
    children?: ReactNode
    className?: string
    disabled?: boolean
    icon?: IconType
    iconColor: string
    open: boolean
    onToggle: (open: boolean) => void
}

export default ({ arrowColor, children, className, disabled = false, icon, iconColor, open, onToggle }: Props) => (
    <Toggle
        $disabled={disabled}
        as={disabled ? 'div' : undefined}
        className={className}
        onClick={() => onToggle(!open)}
    >
        {icon && <Icon color={iconColor} height={16} icon={icon} width={16} />}
        {children}
        {!disabled && <Arrow $open={open} color={arrowColor} height={14} icon="navigate-down" width={14} />}
    </Toggle>
)
