import { useMemo } from 'react'
import { dateWithNoTime } from '../utilities/date'

export function useIsNextDisabled(dates: Date[], max?: Date) {
    return useMemo(() => {
        if (!max) {
            return false
        }

        const last = dates[dates.length - 1]

        if (last && last.getTime() >= dateWithNoTime(max).getTime()) {
            return true
        }

        return false
    }, [dates, max])
}
