import styled, { css } from 'styled-components'
import { CONTROL_SPACING } from '../../../constants'

export const Container = styled.div<{ $compact: boolean }>`
    display: flex;
    flex-wrap: wrap;
    gap: ${CONTROL_SPACING}px;

    ${({ $compact }) =>
        $compact &&
        css`
            > div {
                width: 100%;
            }
        `}
`
