import { Gradient } from 'types'
import { Block, Cascade } from './style'

export const CASCADE_GRADIENTS: Gradient[] = ['pink-red', 'red-yellow', 'blue-green']
export const CASCADE_SIZE: number = 3
export const CASCADE_OFFSET: number = CASCADE_SIZE * CASCADE_GRADIENTS.length

type Props = {
    className?: string
}

export default ({ className }: Props) => (
    <Cascade className={className}>
        {[...CASCADE_GRADIENTS].reverse().map((gradient, i) => {
            const offset = (CASCADE_GRADIENTS.length - i) * CASCADE_SIZE

            return (
                <Block
                    $gradient={gradient}
                    key={`media-preview-cascade-${gradient}-${i}`}
                    style={{ translate: `${offset}px ${offset}px` }}
                />
            )
        })}
    </Cascade>
)
