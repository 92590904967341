import { Gradient } from 'types/src/gradient'

// trending
export const GPS_NO_TREND_TAG = 'giphygpsnotrend'
export const TRENDING_TAG = 'giphytrending'
export const TRENDING_QUEUE_TAG = 'giphytrendingqueue'
export const TRENDING_SCHEDULE_TAG = 'giphytrendingschedule'

// tags
export const FEATURED_TAG_GRADIENT: Gradient = 'blue-green'
export const REMOVE_TAG_GRADIENT: Gradient = 'red-pink'
