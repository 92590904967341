import { ReactNode } from 'react'
import { EditPanelInternalActionContext } from '../contexts/internal-action'
import { EditPanelInternalAction } from '../types/action'

const EditPanelInternalActionContextManager = ({ children }: { children: ReactNode }) => {
    async function performInternalAction<T>(action: EditPanelInternalAction, value: T) {
        switch (action) {
            case 'copy-all-tags':
            case 'copy-gif-id':
                return navigator.clipboard.writeText(value as string)
        }
    }

    return (
        <EditPanelInternalActionContext.Provider value={{ performInternalAction }}>
            {children}
        </EditPanelInternalActionContext.Provider>
    )
}

export default EditPanelInternalActionContextManager
