import styled from 'styled-components'
import { CONTROL_SPACING_LARGE, GRID_SPACING } from '../../constants'

export const Container = styled.div`
    align-items: center;
    display: flex;
    gap: ${GRID_SPACING}px;
`

export const Label = styled.span<{ $color: string }>`
    color: ${({ $color }) => $color};
    flex-shrink: 0;
    font-size: 13px;
    font-weight: bold;
`

export const Item = styled.div`
    align-items: center;
    display: flex;
    gap: ${CONTROL_SPACING_LARGE}px;
    width: auto;
`
