import { giphyYellow } from '@giphy/colors'
import styled, { css, keyframes } from 'styled-components'
import { Gradient } from 'types/src/gradient'
import { colorFromGradientPosition, gradientToCss } from 'utils'
import { textColorFromBackgroundColor } from '../../../../utils/color'

const fadeIn = keyframes`
    0% { opacity: 0; }
`

const popIn = keyframes`
    0% { opacity: 0; scale: 0.5; }
    100% { opacity: 1; scale: 1; }
`

export const Block = styled.button<{ $dimmed: boolean; $disabled: boolean; $selected: boolean; $today: boolean }>`
    animation: ${fadeIn} 0.15s ease-out;
    background: transparent;
    border: none;
    border-radius: 0;
    color: inherit;
    cursor: pointer;
    padding: 0;
    user-select: none;

    &:active {
        opacity: ${({ $dimmed }) => ($dimmed ? 0.3 : 0.8)};
    }

    &:focus-visible {
        border-radius: 4px;
        outline: 2px solid ${giphyYellow};
    }

    ${({ $selected, $today }) => {
        const bold = $selected || $today

        return css`
            font-size: ${bold ? 1 : 0.875}em;
            font-weight: ${bold ? 'bold' : 'normal'};
        `
    }}

    ${({ $dimmed, $disabled }) =>
        ($dimmed || $disabled) &&
        css`
            opacity: ${$disabled ? 0.1 : 0.5};
        `}

    ${({ $disabled }) =>
        $disabled &&
        css`
            pointer-events: none;
        `}
`

export const Selected = styled.div<{ $color: string; $gradient?: Gradient }>`
    align-items: center;
    animation: ${popIn} 0.25s cubic-bezier(0.34, 1.56, 0.64, 1);
    aspect-ratio: 1/1;
    border-radius: 50%;
    display: flex;
    height: 100%;
    justify-content: center;

    ${({ $color, $gradient }) => {
        const backgroundColor = $gradient ? colorFromGradientPosition($gradient, 0.5) : $color
        const color = textColorFromBackgroundColor(backgroundColor)
        const background = $gradient ? gradientToCss($gradient) : $color

        return css`
            background: ${background};
            color: ${color};
        `
    }}
`
