import { IGif } from '@giphy/js-types'
import { ReactNode, useMemo } from 'react'
import { ControlSize } from 'types/src/controls'
import Image from './image'
import { Container } from './style'
import Video from './video'

type Props = {
    backgroundColor?: string
    children?: ReactNode
    className?: string
    gif: IGif
    height: number
    muteColor: string
    muteSize: ControlSize
    width: number
}

export default ({ backgroundColor, children, className, gif, height, muteColor, muteSize, width }: Props) => {
    const aspectRatio = gif.images.original.width / gif.images.original.height
    const isVideo = gif.type === 'video' && !!gif.video

    const [displayWidth, displayHeight] = useMemo(() => {
        let w = width
        let h = Math.round(w / aspectRatio)

        if (h > height) {
            h = height
            w = Math.round(h * aspectRatio)
        }

        return [w, h]
    }, [aspectRatio, height, width])

    return (
        <Container className={className} style={{ backgroundColor, height, width }}>
            {isVideo ? (
                <Video
                    gif={gif}
                    height={displayHeight}
                    muteColor={muteColor}
                    muteSize={muteSize}
                    width={displayWidth}
                />
            ) : (
                <Image gif={gif} height={displayHeight} width={displayWidth} />
            )}
            {children}
        </Container>
    )
}
