import { useContext, useState } from 'react'
import useAsyncEffect from 'utils/src/hooks/use-async-effect'
import { EditPanelApiContext } from '../../../contexts/api'
import { EditPanelMessageContext } from '../../../contexts/message'
import { EditPanelContext } from '../../../contexts/panel'
import { tagStringsFromGifs } from '../../../utilities/tags'

export function useTagCounts() {
    const { fetchTagCounts } = useContext(EditPanelApiContext)
    const { showErrorMessage } = useContext(EditPanelMessageContext)
    const { gifs, gifIds, permissions } = useContext(EditPanelContext)
    const [state, setState] = useState<{ [key: string]: number }>({})
    const tagString = tagStringsFromGifs(gifs, permissions.staff).join(',')
    const hasPermission = permissions.tagCounts || permissions.staff

    useAsyncEffect(async () => {
        if (hasPermission && gifs.length === 1 && tagString) {
            try {
                const counts = await fetchTagCounts(gifIds[0])
                setState(counts)
            } catch (err) {
                showErrorMessage(`An error occurred while fetching tag counts`)
            }
        }
    }, [gifIds[0], gifs.length, permissions.staff, tagString])

    return state
}
