import { useMemo } from 'react'
import { Content, Dimmed } from './style'
import { componentFromObject } from './utilities/component'
import { prefixFromObject, suffixFromObject } from './utilities/object'

type Props = {
    code: string
}

export default ({ code }: Props) => {
    const json: JSON | undefined = useMemo(() => {
        try {
            return JSON.parse(code)
        } catch (err) {
            return
        }
    }, [code])

    if (!json) {
        return null
    }

    return (
        <>
            <Dimmed>{prefixFromObject(json)}</Dimmed>
            <Content>{componentFromObject(json)}</Content>
            <Dimmed>{suffixFromObject(json)}</Dimmed>
        </>
    )
}
