import styled, { css, keyframes } from 'styled-components'
import LoadingComponent from './components/loading'
import { GRID_SPACING, GRID_SPACING_LARGE } from './constants'
import FooterComponent from './footer'
import HeaderComponent from './header'

const fadeIn = keyframes`
    0% { opacity: 0; }
    100% { opacity: 1; }
`

export const Content = styled.div`
    padding: ${GRID_SPACING}px 0;

    > div {
        animation: ${fadeIn} 0.15s ease-out;
        display: flex;
        flex-direction: column;
        gap: ${GRID_SPACING_LARGE}px;
    }
`

export const Divider = styled.div<{ $color: string }>`
    border-bottom: 1px solid ${({ $color }) => $color};
`

export const Header = styled(HeaderComponent)`
    flex-shrink: 0;
    z-index: 1;
`

export const Footer = styled(FooterComponent)`
    flex-shrink: 0;
    margin-top: auto;
    z-index: 1;
`

export const Container = styled.div<{ $color: string; $compact: boolean; $textColor: string }>`
    background: ${({ $color }) => $color};
    box-sizing: border-box;
    color: ${({ $textColor }) => $textColor};
    display: flex;
    flex-direction: column;
    font-size: 15px;
    min-height: 100%;
    position: relative;

    ${({ $compact }) =>
        !$compact &&
        css`
            ${Content} {
                padding: ${GRID_SPACING_LARGE}px ${GRID_SPACING}px;
            }

            ${Header} {
                margin-top: ${GRID_SPACING}px;
                padding: 0 ${GRID_SPACING}px;
            }
        `}
`

export const Loading = styled(LoadingComponent)`
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
`
