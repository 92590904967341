import { useMemo } from 'react'
import { dateWithNoTime } from '../utilities/date'

export function useIsInRange(date: Date, min?: Date, max?: Date): boolean {
    return useMemo(() => {
        const d = dateWithNoTime(date)

        if (min && d < dateWithNoTime(min)) {
            return false
        }

        if (max && d > dateWithNoTime(max)) {
            return false
        }

        return true
    }, [date, max, min])
}
