import { updateGifs } from '@/api/edit/gif'
import { GPS_NO_TREND_TAG } from 'edit/constants'
import { EditPanelField } from 'edit/panel/types/field'
import { EditPanelGif } from 'edit/panel/types/gif'
import { valueKeyFromField } from 'edit/panel/utilities/field'
import { addTagStrings, removeTagString } from '../../api/edit/tag'

export async function getEditPanelChanges<T>(field: EditPanelField, value: T): Promise<Partial<EditPanelGif>> {
    const valueKey = valueKeyFromField(field)
    const changes: { [key: string]: T } = {}

    changes[valueKey] = value

    return changes
}

export async function applyEditPanelChange<T>(
    gifs: EditPanelGif[],
    field: EditPanelField,
    value: T
): Promise<EditPanelGif[]> {
    const changes = await getEditPanelChanges<T>(field, value)

    return gifs.map((gif) => ({ ...gif, ...changes }))
}

export async function performEditPanelChange<T>(gifs: EditPanelGif[], field: EditPanelField, value: T) {
    const gifIds = gifs.map((g) => `${g.id}`)
    const valueKey = valueKeyFromField(field)

    switch (field) {
        // case 'cta':
        //     return updateCtas(gifIds, (value as Cta).link, (value as Cta).text)
        case 'no-trend':
            return !!value ? addTagStrings(gifIds, [GPS_NO_TREND_TAG]) : removeTagString(gifIds, GPS_NO_TREND_TAG)
        default:
            return updateGifs(gifIds, valueKey, value)
    }
}
