import { useContext } from 'react'
import { CalendarContext } from '../context'
import { useIsInRange } from '../hooks/use-is-in-range'
import { useIsSameDay } from '../hooks/use-is-same-day'
import { dateWithTime } from '../utilities/date'
import { Block, Selected } from './style'

type Props = {
    date: Date
    dimmed?: boolean
}

const CalendarDatesBlock = ({ date, dimmed = false }: Props) => {
    const { colors, max, min, selected, time, today, setSelected } = useContext(CalendarContext)
    const inRange = useIsInRange(date, min, max)
    const isSelected = useIsSameDay(date, selected)
    const isToday = useIsSameDay(date, today)
    const color = isToday ? colors.highlight : colors.default
    const label = date.getDate()

    function onClick() {
        const d = dateWithTime(date, time)
        setSelected(d)
    }

    return (
        <Block
            $dimmed={dimmed}
            $disabled={!inRange}
            $selected={isSelected}
            $today={isToday}
            style={{ color }}
            tabIndex={dimmed ? -1 : 0}
            onClick={() => onClick()}
        >
            {isSelected ? (
                <Selected $color={colors.highlight} $gradient={colors.gradient}>
                    {label}
                </Selected>
            ) : (
                label
            )}
        </Block>
    )
}

export default CalendarDatesBlock
