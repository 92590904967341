import styled from 'styled-components'
import IconComponent from 'ui/src/components/icon'
import { borderRadiusFromControlSize } from 'utils/src/control-size'
import MediaPreview from '../../../controls/media/preview'
import { CONTROL_SIZE, GRID_SPACING } from '../../constants'

export const Footer = styled.div`
    align-items: center;
    display: flex;
    gap: ${GRID_SPACING}px;
    padding-top: ${GRID_SPACING}px;
    width: 100%;
`

export const Preview = styled(MediaPreview)`
    border-radius: ${borderRadiusFromControlSize(CONTROL_SIZE)}px;
`

export const ViewsIcon = styled(IconComponent)`
    margin-right: 6px;
`

export const Views = styled.div`
    align-items: center;
    display: flex;
    flex-shrink: 0;

    &:only-child {
        margin-left: auto;
    }
`
