import { TRENDING_QUEUE_TAG, TRENDING_TAG } from 'edit/constants'
import { addTagStrings, removeTagString } from './tag'

export const queueGifsToTrend = async (gifIds: string[]) => {
    return addTagStrings(gifIds, [TRENDING_QUEUE_TAG])
}

export const trendGifs = async (gifIds: string[]) => {
    return Promise.all([removeTagString(gifIds, TRENDING_QUEUE_TAG), addTagStrings(gifIds, [TRENDING_TAG])])
}
