import { giphyBlack } from '@giphy/colors'
import styled, { css } from 'styled-components'
import { brightnessFromColor } from 'utils/src/color'

export const Block = styled.div<{ $color: string; $resizeable: boolean; $textColor?: string }>`
    border-radius: 4px;
    box-sizing: border-box;
    min-height: 3em;
    overflow: auto;
    padding: 0.5em 1em;
    white-space: pre-wrap;
    word-wrap: break-word;

    ${({ $color, $textColor }) => {
        const color = brightnessFromColor($color) > 0.5 ? giphyBlack : 'inherit'

        return css`
            background: ${$color};
            color: ${$textColor || color};
        `
    }}

    ${({ $resizeable }) =>
        $resizeable &&
        css`
            resize: vertical;
        `}
`
