import styled from 'styled-components'
import ButtonComponent from 'ui/src/components/controls/button'

export const Button = styled(ButtonComponent)`
    flex-shrink: 0;

    > span {
        padding-top: 2px;
    }

    i,
    svg {
        display: inline-block;
        margin-right: 0.6em;
        margin-top: -2px;
        vertical-align: middle;
    }
`
