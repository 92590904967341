import { IGif } from '@giphy/js-types'
import { getBestRenditionUrl } from '@giphy/js-util'
import { useMemo } from 'react'
import { Image } from './style'

type Props = {
    gif: IGif
    height: number
    width: number
}

export default ({ gif, height, width }: Props) => {
    const src = useMemo(() => getBestRenditionUrl(gif, width, height), [gif.id, height, width])

    return <Image $sticker={gif.is_sticker} alt="" src={src} style={{ height, width }} />
}
