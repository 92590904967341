import styled from 'styled-components'
import Button from 'ui/src/components/controls/button'
import IconComponent from 'ui/src/components/icon'
import { borderRadiusFromControlSize } from 'utils/src/control-size'
import CodeBlock from '../../../../controls/code'
import { CONTROL_SIZE, CONTROL_SPACING } from '../../../constants'

export const Arrow = styled(IconComponent)`
    display: inline-block;
    vertical-align: top;
`

export const Code = styled(CodeBlock)`
    border-radius: ${borderRadiusFromControlSize(CONTROL_SIZE)}px;
    height: 100px;
`

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${CONTROL_SPACING}px;
`

export const RawButton = styled(Button)`
    padding: 0;
`
