import styled from 'styled-components'
import ButtonComponent from 'ui/src/components/controls/button'
import IconComponent from 'ui/src/components/icon'

export const Button = styled(ButtonComponent)`
    align-items: center;
    display: flex;
    font-weight: normal;
    text-align: left;
    width: 100%;
`

export const Icon = styled(IconComponent)`
    flex-shrink: 0;
    opacity: 0.5;
`
