import styled from 'styled-components'
import TagInput from '../../../../controls/tag/input'
import { CONTROL_SPACING } from '../../../constants'

export const Container = styled.div`
    display: flex;
    gap: ${CONTROL_SPACING}px;
    margin-bottom: 15px;
`

export const Input = styled(TagInput)`
    width: 100%;

    > button {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
    }
`
