import { Fragment, useContext } from 'react'
import TooltipButton from '../components/button/tooltip'
import { FOOTER_SIZE, TOOLTIP_WIDTH_SMALL } from '../constants'
import { EditPanelContext } from '../contexts/panel'
import { EditPanelAction } from '../types/action'
import { hasActionPermission, hasFieldPermission } from '../utilities/permissions'
import { ellipsisMenuActionsFromTab } from '../utilities/tab'
import { Button, Divider, Footer, PrivacyToggle } from './style'
import EllipsisTooltip from './tooltips/ellipsis'
import ScheduleTooltip from './tooltips/schedule'

type Props = {
    className?: string
}

const EditPanelFooter = ({ className }: Props) => {
    const { actions, colors, isCompact, permissions, tab, width } = useContext(EditPanelContext)
    const iconsOnly = isCompact || width < 700

    const ellipsisActions = ellipsisMenuActionsFromTab(tab)
    const filteredEllipsisActions = ellipsisActions.filter((action) => {
        // disable copy actions if no clipboard available
        if (!navigator.clipboard && (action === 'copy-all-tags' || action === 'copy-gif-id')) {
            return false
        }

        return hasActionPermission(action, permissions)
    })

    function getTooltip(action: EditPanelAction) {
        switch (action) {
            case 'schedule':
                return <ScheduleTooltip />
            default:
                return
        }
    }

    return (
        <Footer
            $backgroundColor={colors.backgroundColor}
            $color={colors.footerBackground}
            $compact={isCompact}
            $dividerColor={colors.divider}
            className={className}
        >
            {!isCompact && hasFieldPermission('privacy', permissions) && (
                <PrivacyToggle field="privacy" size={FOOTER_SIZE} />
            )}
            {actions.map((action, i) => (
                <Fragment key={`edit-panel-footer-${action}-${i}`}>
                    {iconsOnly && action === 'delete' && actions.length > 1 && <Divider />}
                    <Button action={action} iconOnly={iconsOnly} size={FOOTER_SIZE}>
                        {getTooltip(action)}
                    </Button>
                </Fragment>
            ))}
            {filteredEllipsisActions.length > 0 && (
                <TooltipButton
                    color="transparent"
                    icon="ellipsis"
                    iconColor={colors.footerEllipsis}
                    iconOnly
                    iconScale={1.1}
                    label="More Options"
                    size={FOOTER_SIZE}
                    style={actions.length === 0 ? { marginLeft: 'auto' } : undefined}
                    tooltipWidth={TOOLTIP_WIDTH_SMALL}
                >
                    <EllipsisTooltip actions={filteredEllipsisActions} />
                </TooltipButton>
            )}
        </Footer>
    )
}

export default EditPanelFooter
