import styled from 'styled-components'
import IconButton from 'ui/src/components/controls/icon-button'

export const Button = styled(IconButton)`
    flex-shrink: 0;
    width: 34px;
`

export const Header = styled.div`
    align-items: flex-end;
    display: flex;
    gap: 3px;
    width: 100%;
`

export const Title = styled.div`
    font-weight: bold;
    white-space: nowrap;
    width: 100%;
`
