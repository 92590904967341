import { giphyCharcoal, giphyLightCharcoal, giphyLightGrey } from '@giphy/colors'
import { CSSProperties, ReactNode, useEffect, useRef, useState } from 'react'
import IconType from 'ui/src/types/icons'
import { Container, Content, Title, Toggle } from './style'

type Props = {
    arrowColor?: string
    children: ReactNode
    className?: string
    color?: string
    defaultOpen?: boolean
    disabled?: boolean
    icon?: IconType
    iconColor?: string
    open?: boolean
    title: ReactNode
    titleComponent?: ReactNode
    style?: CSSProperties
    onToggle?: (open: boolean) => void
}

const Collapsible = ({
    arrowColor = giphyCharcoal,
    children,
    className,
    color = giphyLightGrey,
    defaultOpen = false,
    disabled = false,
    icon,
    iconColor = giphyLightCharcoal,
    open,
    title,
    titleComponent,
    style,
    onToggle,
}: Props) => {
    const defaultOpenRef = useRef<boolean | undefined>(open || defaultOpen)
    const [isOpen, setIsOpen] = useState<boolean>(open || defaultOpen)

    useEffect(() => {
        if (open !== undefined) {
            setIsOpen(open)
        }
    }, [open])

    useEffect(() => {
        if (isOpen !== defaultOpenRef.current) {
            defaultOpenRef.current = undefined
            onToggle?.(isOpen)
        }
    }, [isOpen])

    return (
        <Container className={className} style={style}>
            <Title $color={color}>
                <Toggle
                    arrowColor={arrowColor}
                    disabled={disabled}
                    icon={icon}
                    iconColor={iconColor}
                    open={isOpen}
                    onToggle={setIsOpen}
                >
                    {title}
                </Toggle>
                {titleComponent}
            </Title>
            {isOpen ? <Content $ignoreAnimation={!!defaultOpenRef.current}>{children}</Content> : null}
        </Container>
    )
}

export default Collapsible
