import { IGif } from '@giphy/js-types'
import { getBestVideo } from '@giphy/js-util'
import { useMemo, useState } from 'react'
import { ControlSize } from 'types/src/controls'
import { Mute, Video, Wrapper } from './style'

type Props = {
    gif: IGif
    height: number
    muteColor: string
    muteSize: ControlSize
    width: number
}

export default ({ gif, height, muteColor, muteSize, width }: Props) => {
    const [muted, setMuted] = useState<boolean>(true)

    const src = useMemo(() => {
        return getBestVideo(gif.video, width, height)?.url || ''
    }, [gif.id, height, width])

    return (
        <Wrapper style={{ height, width }}>
            <Video autoPlay loop muted={muted} src={src} />
            <Mute
                color="transparent"
                icon={muted ? 'sound-off' : 'sound-on'}
                iconColor={muteColor}
                size={muteSize}
                onClick={() => setMuted(!muted)}
            />
        </Wrapper>
    )
}
