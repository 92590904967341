import { ReactNode, useMemo, useState } from 'react'
import { previewFromObject } from '../utilities/object'
import { Arrow, Button, Content, Label, Preview } from './style'

type Props = {
    children?: ReactNode
    className?: string
    defaultOpen?: boolean
    label: string
    value: any
}

export default ({ children, className, defaultOpen = false, label, value }: Props) => {
    const [isOpen, setIsOpen] = useState<boolean>(defaultOpen)
    const preview = useMemo(() => previewFromObject(value), [value])

    return (
        <div className={className}>
            <Label>
                <Button title={label} onClick={() => setIsOpen(!isOpen)}>
                    <Arrow $open={isOpen} color="currentColor" icon="navigate-down" height={10} width={10} />
                    <strong>{label}</strong>:
                </Button>
                {!isOpen && <Preview title={preview}>{preview}</Preview>}
            </Label>
            {isOpen && <Content>{children}</Content>}
        </div>
    )
}
