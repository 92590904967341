import { IGif } from '@giphy/js-types'
import Icon from 'ui/src/types/icons'
import { ContentType } from '../types'

export function contentTypeFromGif(gif: IGif): ContentType {
    if (gif.is_sticker) {
        return 'sticker'
    }
    if (gif.type === 'video') {
        return 'clip'
    }
    return 'gif'
}

export function iconFromContentType(type: ContentType): Icon {
    switch (type) {
        case 'clip':
            return 'clip'
        case 'gif':
            return 'gif'
        case 'sticker':
            return 'sticker'
    }
}

export function titleFromContentType(type: ContentType): string {
    switch (type) {
        case 'clip':
            return 'Clip'
        case 'gif':
            return 'GIF'
        case 'sticker':
            return 'Sticker'
    }
}
