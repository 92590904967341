import { useMemo } from 'react'
import { ControlSize } from 'types/src/controls'
import { Input } from './style'

type Props = {
    className?: string
    color: string
    disabled?: boolean
    max?: number
    min?: number
    size: ControlSize
    value: number
    onChange: (value: number) => void
}

export default ({ className, color, disabled, max = 59, min = 0, size, value, onChange: onChangeProp }: Props) => {
    const displayValue = useMemo(() => `${value}`.padStart(2, '0'), [value])

    function onChange(text: string) {
        if (!text) return

        let num = parseInt(text)

        if (isNaN(num)) {
            onChangeProp(min)
            return
        }

        onChangeProp(Math.max(min, Math.min(max, num)))
    }

    function onKeyPress(key: string, shift: boolean) {
        const step = shift ? 10 : 1

        if (key === 'ArrowUp') {
            if (value + step > max) {
                onChangeProp(((value + step) % (max + 1)) + min)
            } else {
                onChangeProp(value + step)
            }
        }

        if (key === 'ArrowDown') {
            if (value - step < min) {
                onChangeProp(max + value - step - min + 1)
            } else {
                onChangeProp(value - step)
            }
        }
    }

    return (
        <Input
            $size={size}
            className={className}
            color={color}
            delay={800}
            placeholder="--"
            selectOnFocus
            size={size}
            tabIndex={disabled ? -1 : undefined}
            value={displayValue}
            onChange={onChange}
            onKeyPress={onKeyPress}
        />
    )
}
