import { EditPanelAction } from '../types/action'
import { EditPanelField } from '../types/field'
import { EditPanelTab, editPanelTabs } from '../types/tab'

export function actionsFromTab(tab: EditPanelTab): EditPanelAction[] {
    switch (tab) {
        case 'collections':
            return []
        case 'info':
            return ['trend', 'schedule', 'queue', 'delete']
        case 'metadata':
            return ['delete']
    }
}

export function ellipsisMenuActionsFromTab(tab: EditPanelTab): EditPanelAction[] {
    switch (tab) {
        default:
            return ['explicit', 'repair', 'remove-all-tags', 'copy-all-tags', 'takedown']
    }
}

export function fieldsFromTab(tab: EditPanelTab): EditPanelField[] {
    switch (tab) {
        case 'collections':
            return []
        case 'info':
            return [
                'username',
                'title',
                'description',
                //'alt-text',
                'source',
                'rating',
                'no-trend',
            ]
        case 'metadata':
            return ['source-body', 'source-caption', 'slug', 'content-url'] as EditPanelField[]
    }
}

export function getTabs(showCollections: boolean) {
    if (showCollections) {
        return editPanelTabs
    }
    return editPanelTabs.filter((tab) => tab !== 'collections')
}

export function labelFromTab(tab: EditPanelTab): string {
    switch (tab) {
        case 'collections':
            return 'Collections'
        case 'info':
            return 'Info'
        case 'metadata':
            return 'Metadata'
    }
}
