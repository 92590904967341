import { ReactNode, useContext } from 'react'
import { PortalMessageContext, PortalMessageContextManager } from 'ui/src/components/message/portal-message/context'

const EditPanelPortalMessageContextManager = ({ children }: { children: ReactNode }) => {
    const { showMessage } = useContext(PortalMessageContext)

    if (!!showMessage) {
        return children
    }

    return <PortalMessageContextManager>{children}</PortalMessageContextManager>
}

export default EditPanelPortalMessageContextManager
