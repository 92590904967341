import { createContext } from 'react'
import { CalendarColors, CalendarVisibleMonth } from './types'

export type CalendarContextProps = {
    colors: CalendarColors
    id: string
    max?: Date
    min?: Date
    selected?: Date
    time: string
    today: Date
    visible: Date
    visibleMonth: CalendarVisibleMonth
    setSelected: (date?: Date) => void
    setTime: (time: string) => void
    setVisible: (date: Date) => void
}

export const CalendarContext = createContext({} as CalendarContextProps)
